import { produce } from "immer"
export const SET_TAB = "SET_GROUP"

export const initialState = {
  selectedTab: 1,
}

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case SET_TAB:
      draft.selectedTab = action.value
      break
    default:
      return draft
  }
})
