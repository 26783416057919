import { CircularProgress } from "@material-ui/core"
import React from "react"

export default function Loading({ color = "primary", size = 50 }) {
  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress
        style={{
          color,
        }}
        size={size}
      />
    </div>
  )
}
