import { Subject } from "rxjs"
import { filter } from "rxjs/operators"

const subject = new Subject()

export const ApxEventHandler = {
  init: () => subject.next({ name: null }),
  /**
   * ApxEventHandler is not responsible for unsubscribing.
   * The event subscribers must call `unsubscribe`
   */
  subscribe: (eventName, callback) =>
    subject.pipe(filter((f) => f.name === eventName)).subscribe(callback),
  /**
   * Here the assumption is that, the event subscriber must
   * know format of that data that it is going to receive
   * from the publisher.
   *
   * The EventDispatcher doesn't impose any data format.
   * It is upto the event publishers what format they publish.
   */
  dispatchEvent: (data) => subject.next(data),
}

export const dispatchEvent = (eventName, data) => {
  ApxEventHandler.dispatchEvent({
    name: eventName,
    ...data,
  })
}

export const sendEvent = (eventName, data) => {
  ApxEventHandler.dispatchEvent({
    name: eventName,
    ...data,
  })
}
