import { Paper, withStyles } from "@material-ui/core"

const StyledPaper = withStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    border: `1px solid #E4E7E9`,
    borderRadius: "12px",
  },
}))(Paper)

/**
 * A white paper with a border, with no shadows or elevation.
 * @param {import("react").ReactElement} children The children to render inside the paper.
 * @param {import("@material-ui/core").PaperProps} props The props to pass to the paper.
 * @returns {import("react").ReactElement} The paper component.
 */
export default function WhitePaper({ children, ...props }) {
  return (
    <StyledPaper
      elevation={0}
      variant="outlined"
      style={{}}
      {...props}
    >
      {children}
    </StyledPaper>
  )
}
