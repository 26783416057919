import Divider from "@material-ui/core/Divider"
import Drawer from "@material-ui/core/Drawer"
import { MuiThemeProvider, withStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import { useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import { darkTheme } from "../../../../styling"
import { notOnDashboard } from "../../../../utils/index"
import SideBar from "./components/SideBar"
import { styles } from "./styling/CustomCSS"

const Navigation = (props) => {
  const { appId, location, classes, updateModes } = props
  const showSidebar = false

  useEffect((props) => {
    if (notOnDashboard(location)) {
      props.handleDrawerClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { orgId } = useParams()

  return (
    <MuiThemeProvider theme={darkTheme}>
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(
            classes.drawerPaper,
            !showSidebar && classes.drawerPaperClose,
          ),
        }}
        open={showSidebar}
      >
        <div className={classes.drawerInner}>
          <div className={classes.drawerHeader}>
            <Link to={`/orgs/${orgId}/apps`}>
              <img
                alt={window.apxorAppInfo.whiteLogo}
                src="/assets/img/sidebar_logo.png"
                style={{
                  maxHeight: "40px",
                }}
              />
            </Link>
          </div>
          <Divider />
          <SideBar
            appId={appId}
            updateModes={updateModes}
            {...props}
            isSidebarOpen={false}
            session={{ user: props.user }}
            handleNavigationClick={() => {
              if (props.open) {
                props.handleDrawerClose()
              }
            }}
          />
        </div>
      </Drawer>
    </MuiThemeProvider>
  )
}

export default withStyles(styles, { withTheme: true })(Navigation)
