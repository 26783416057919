import { Typography } from "@material-ui/core"
import { Suspense, lazy, useState, useTransition } from "react"
import { apxTheme } from "../../ApplicationFrame/styling/CustomCSS"
import HStack from "../../ReusableComponents/HStack"
import Loading from "../../ReusableComponents/Loading"
import SearchBar from "../../ReusableComponents/SearchBar"
import VStack from "../../ReusableComponents/VStack"
import { OrgsGridSkeleton } from "../components/OrgsGrid"
import OrgLayout from "../layouts/OrgLayout"

const ApprovalAppsGrid = lazy(() => import("../components/ApprovalAppsGrid"))

export default function AppApprovalsPage() {
  const [searchText, setSearchText] = useState("")
  const [isPending, startTransition] = useTransition()

  return (
    <OrgLayout>
      <VStack
        spacing="50px"
        style={{
          padding: 40,
          backgroundColor: "#F9F9F9",
          minHeight: "100vh",
        }}
      >
        <HStack justify="space-between">
          <Typography
            color="textPrimary"
            style={{
              fontSize: 24,
              fontWeight: 700,
            }}
          >
            Pending App Approvals
          </Typography>
          <HStack spacing="8px">
            {isPending && (
              <Loading
                color={apxTheme.palette.primary.main}
                size={20}
              />
            )}
            <SearchBar
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value)
              }}
              onClear={() => {
                setSearchText("")
              }}
              placeholder="Search by App Name"
              style={{
                minWidth: "300px",
              }}
            />
          </HStack>
        </HStack>
        <Suspense fallback={<OrgsGridSkeleton />}>
          <ApprovalAppsGrid
            searchText={searchText}
            startTransition={startTransition}
          />
        </Suspense>
      </VStack>
    </OrgLayout>
  )
}
