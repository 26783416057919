import { IconButton } from "@material-ui/core"
import CheckMarkIcon from "@material-ui/icons/DoneAllOutlined"
import CopyIcon from "@material-ui/icons/FileCopyOutlined"
import { useEffect, useState } from "react"
import generateShortID, { copyToClipboard } from "../../../../utils"
import { apxTheme } from "../../../ApplicationFrame/styling/CustomCSS"

export default function CopyContent({ copyText, styles = {} }) {
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 5000)
    }
  }, [copied])

  return (
    <IconButton
      size="small"
      disableFocusRipple
      disableRipple
      disableTouchRipple
      id={`copy:${copyText}-${generateShortID(":")}`}
      color="secondary"
      aria-label="copy code"
      style={{
        color: copied
          ? apxTheme.palette.success.main
          : apxTheme.palette.text.hint,
        position: "absolute",
        top: "1.4em",
        right: ".5em",
        cursor: copied ? "" : "pointer",
        ...styles,
      }}
      onClick={() => {
        if (!copied) {
          copyToClipboard(copyText)
          setCopied(true)
        }
      }}
    >
      {copied ? (
        <CheckMarkIcon fontSize="small" />
      ) : (
        <CopyIcon fontSize="small" />
      )}
    </IconButton>
  )
}
