import { useReducer } from "react"
import { createContainer } from "react-tracked"
import { initial_state, reducer } from "./reducer"

import { enableES5, setAutoFreeze } from "immer"

/*
 * Let copies stay put without freezing.
 * Performing here instead of in individual reducers because :
 * it's a global setting,
 * this is the outermost Provider.
 */
setAutoFreeze(false)
enableES5(true)

const useValue = () => useReducer(reducer, initial_state)

export const {
  Provider,
  useTracked,
  useTrackedState,
  useUpdate: useDispatch,
} = createContainer(useValue)
