import React from "react"

import { APP_PLATFORMS } from "../../../constants"
import { AndroidLogo, AppleLogo, WebLogo } from "./Logos"

export const styles = (theme) => ({
  card: {
    width: "100%",
    maxWidth: 300,
    cursor: "pointer",
  },
  root: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  content: {
    padding: theme.spacing(4),
  },
  actions: {
    justifyContent: "flex-end",
    padding: 0,
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    margin: `${theme.spacing(1)}px auto`,
  },
  flexGrow: {
    flex: "1 1 auto",
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  addIcon: {
    width: 64,
    height: 64,
  },
  addNewButton: {
    margin: `${theme.spacing(4)}px 0 ${theme.spacing(4)}px auto`,
    display: "block",
  },
  divider: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
  },
})

export const appComponentStyle = (theme) => ({
  root: {
    width: "100%",
    borderRadius: 10,
    transition: "all 0.2s ease-in-out",
    padding: 16,
    boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    "&:hover": {
      // border: "1px solid #4177F6"
    },
  },
  root_pending: {
    width: "100%",
    borderRadius: 10,
    transition: "all 0.2s ease-in-out",
    padding: 16,
    boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    backgroundImage:
      "linear-gradient(135deg, #f6f7ff 25%, #ededf0 25%, #ededf0 50%, #f6f7ff 50%, #f6f7ff 75%, #ededf0 75%, #ededf0 100%)",
    backgroundSize: "11.31px 11.31px",
    pointerEvents: "none",
    "&:hover": {
      // border: "1px solid #4177F6"
    },
  },
  cardContent: {
    padding: 0,
    overflow: "hidden",
  },
  logoAvatar: {
    marginRight: 12,
    width: 48,
    height: 48,
  },
  appTitle: {
    fontWeight: 600,
    maxWidth: 260,
    width: "100%",
    textOverflow: "ellipsis",
    fontSize: 20,
    color: "#326185",
  },
  appDesc: {
    fontSize: 16,
    color: "#8a8a8a",
  },
  adminAvatar: {
    border: "2px solid white",
    fontSize: 16,
    width: 26,
    height: 26,
    "&:hover": {
      zIndex: 5,
      transform: "scale(1.1)",
    },
  },
  cardActions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 12,
  },
})

export const PLATFORM_IMAGES = (platform, color = "#212121", width = 32) => {
  switch (platform) {
    case APP_PLATFORMS.android:
      return (
        <AndroidLogo
          color={color}
          width={width}
        />
      )
    case APP_PLATFORMS.ios:
      return (
        <AppleLogo
          color={color}
          width={width}
        />
      )
    case APP_PLATFORMS.web:
      return (
        <WebLogo
          color={color}
          width={width}
        />
      )
    case APP_PLATFORMS.omni:
      return (
        <img
          alt={"OMNI_APP"}
          src={"../../../assets/img/omni.png"}
          width={36}
        />
      )
    default:
      return ""
  }
}

export const getPlatformLogoColor = (platform) => {
  switch (platform) {
    case APP_PLATFORMS.web:
      return "#4177F6"
    case APP_PLATFORMS.android:
      return "#3DDC84"
    case APP_PLATFORMS.ios:
      return "#A3AAAE"
    default:
      return null
  }
}
