/* eslint-disable no-unused-vars */
import { useCallback, useMemo } from "react"
import { useLocation } from "react-router-dom"
import { APP_PLATFORMS } from "../../../../constants"
import { useAuth } from "../../../../contexts/AuthContext"
import { isValidEmail } from "../../../../utils"
import {
  useDeepCompareCallback,
  useDeepCompareMemo,
} from "../../../../utils/use-deep-compare"
import {
  addAppAPI,
  deleteAppAPI,
  setMonitoringStatusAPI,
} from "../../../App/actions"
import {
  getPushSettings,
  saveAndroidPushSettings,
} from "../../../Dashboard/components/push/actions"
import {
  FLAGS_ENUM,
  PERMISSIONS_ENUM,
  ROLES_ENUM,
} from "../../../Dashboard/components/settings/constants"
import {
  addNewBundleId,
  getBundleIds,
} from "../../../Dashboard/components/settings/sdk/actions"
import {
  SET_APP_ERROR,
  SET_APP_LOADING,
} from "../../../IntegrationExperience/constants"
import { useDispatch as useAppDispatch } from "../../../IntegrationExperience/store"
import { SET_CONFIRM_PASSWORD, SET_PASSWORD } from "../../constants"
import {
  useDispatch as useAuthDispatch,
  useTrackedState as useAuthState,
} from "../../store"
import {
  checkInvitationRevokedAPI,
  checkUserExistsAPI,
  editUserPermissionsAPI,
  forgotPasswordAPI,
  getAllInviteesAPI,
  integrationAuthFinishAPI,
  integrationAuthStartAPI,
  inviteUserAPI,
  loginAPI,
  resetPasswordAPI,
  revokeInvitationAPI,
  setInviteeStatusAPI,
  updateIntegrationStatusAPI,
} from "../actions"
import { AUTH_PAGE_TYPE, PASSWORD_CHECKS } from "../constants"

/**
 * A custom hook to use the integration experience functionality.
 */
export default function useIntegration() {
  const auth = useAuth()
  const { pageType, resetIntermediateAuthStates, login } = auth
  const {
    user: { name, job_title, email, password, confirm_password, company_name },
  } = useAuthState()
  const appDispatch = useAppDispatch()
  const authDispatch = useAuthDispatch()
  const { search } = useLocation()

  /**
   * This function resets `app_loading` and `app_error` states.
   * @private @memberof {@link useIntegration}
   * @param {Object} payload
   * @param {boolean} payload.loading The loading state to reset with.
   * @param {string} payload.error The error state to reset with.
   */
  const resetIntermediateAppStates = useCallback(
    ({ loading = false, error = "" }) => {
      appDispatch({
        type: SET_APP_LOADING,
        payload: loading,
      })
      appDispatch({
        type: SET_APP_ERROR,
        payload: error,
      })
    },
    [appDispatch],
  )

  /**
   * This function is used to check if the user exists.
   * @public @memberof {@link useIntegration}
   * @param {string} token The auth token to check if the user exists.
   * @returns {Promise<boolean>} Returns a promise that resolves to **true** if the user exists, **false** otherwise.
   */
  const checkIfUserExists = useCallback(async (token) => {
    const { data } = await checkUserExistsAPI(token)
    return !!data
  }, [])

  /**
   * This function is used to check if the invitation is revoked.
   * @public @memberof {@link useIntegration}
   * @param {string} appId The app ID to check if the user's invitation is revoked.
   * @param {string} email The email of the user to check whose invitation is revoked.
   * @returns {Promise<boolean>} Returns a promise that resolves to **true** if the invitation is revoked, **false** otherwise.
   */
  const checkIfInvitationIsRevoked = useCallback(async (appId, email) => {
    const { data } = await checkInvitationRevokedAPI(appId, email)
    return !!data
  }, [])

  /**
   * This function is used to send an email to the user with a link to reset their password.
   * @public @memberof {@link useIntegration}
   * @param {string} email The email of the user to send the reset password link to.
   * @returns {Promise<boolean>} Returns a promise that resolves to **true** if the email is sent successfully, **false** otherwise.
   */
  const sendPasswordResetEmail = useCallback(
    async (email) => {
      try {
        resetIntermediateAuthStates({
          loading: true,
          error: "",
        })
        const sent = await forgotPasswordAPI(email)
        resetIntermediateAuthStates({
          loading: false,
          error: "",
        })
        authDispatch({
          type: SET_PASSWORD,
          value: "",
        })
        authDispatch({
          type: SET_CONFIRM_PASSWORD,
          value: "",
        })
        return sent === true
      } catch (err) {
        resetIntermediateAuthStates({
          loading: false,
          error: err,
        })
        return false
      }
    },
    [authDispatch, resetIntermediateAuthStates],
  )

  /**
   * This function is used to save the FCM Key and Bundle ID of an app.
   * @public @memberof {@link useIntegration}
   * @param {string} appId The ID of the app.
   * @param {string} appName The name of the app.
   * @param {string} platform The platform of the app.
   * @param {string} bundleId The new Bundle ID.
   * @param {string} [fcmKey] The FCM Key. Android Only.
   * @returns {Promise<boolean>} Returns a promise that resolves to **true** if the FCM Key and Bundle ID are saved successfully, **false** otherwise.
   */
  const saveFCMKeyAndBundleID = useDeepCompareCallback(
    async (appId, appName, platform, bundleId, fcmKey = null) => {
      try {
        resetIntermediateAppStates({
          loading: true,
          error: "",
        })

        const bundleIdWithDetails = {
          id: bundleId,
          name: appName + "-" + platform,
          platform,
        }

        let promises = [addNewBundleId(auth, appId, bundleIdWithDetails)]
        if (platform === APP_PLATFORMS.android && fcmKey) {
          promises.push(saveAndroidPushSettings(auth, appId, null, { fcmKey }))
        }

        const responses = await Promise.all(promises)
        if (responses.every((response) => response === true)) {
          resetIntermediateAppStates({
            loading: false,
            error: "",
          })
        }
        return true
      } catch (err) {
        resetIntermediateAppStates({
          loading: false,
          error: err?.message ?? err?.data?.message,
        })
        return false
      }
    },
    [auth, resetIntermediateAppStates],
  )

  /**
   * This function resets the password of a user.
   * @public @memberof {@link useIntegration}
   * @returns {Promise<boolean>} Returns a promise that resolves to **true** if the password is reset successfully, **false** otherwise.
   */
  const resetPassword = useCallback(
    async (newPassword) => {
      try {
        const queryParams = new URLSearchParams(search)
        resetIntermediateAuthStates({
          loading: true,
          error: "",
        })
        const res = await resetPasswordAPI(
          { host: null, token: null },
          queryParams.get("user"),
          newPassword,
          queryParams.get("token"),
        )
        resetIntermediateAuthStates({
          loading: false,
          error: "",
        })
        authDispatch({
          type: SET_PASSWORD,
          value: "",
        })
        authDispatch({
          type: SET_CONFIRM_PASSWORD,
          value: "",
        })
        return !!res
      } catch (err) {
        resetIntermediateAuthStates({
          loading: false,
          error: err?.message ?? err?.data?.message,
        })
        return false
      }
    },
    [authDispatch, resetIntermediateAuthStates, search],
  )

  /**
   * This function logs the user in.
   * @public @memberof {@link useIntegration}
   * @returns {Promise<boolean>} Returns a promise that resolves to **true** if the login was successful, **false** otherwise.
   */
  const loginUser = useCallback(
    async (email, password) => {
      try {
        resetIntermediateAuthStates({
          loading: true,
          error: "",
        })
        const { user } = await loginAPI(email, password)
        login(user)
        resetIntermediateAuthStates({
          loading: false,
          error: "",
        })
        return true
      } catch (err) {
        resetIntermediateAuthStates({
          loading: false,
          error: err?.data?.message,
        })
        return false
      }
    },
    [login, resetIntermediateAuthStates],
  )

  /**
   * This function sends an email magic link inorder to set a new password to the associated user account.
   * @public @memberof {@link useIntegration}
   * @returns {Promise<boolean>} Returns a promise that resolves to `true` if the magic link is sent successfully, `false` otherwise.
   */
  const sendSetPasswordEmail = useCallback(
    async (userDetails) => {
      try {
        resetIntermediateAuthStates({
          loading: true,
          error: "",
        })
        const res = await integrationAuthStartAPI({
          ...userDetails,
        })
        resetIntermediateAuthStates({
          loading: false,
          error: "",
        })
        return !!res?.data && res?.status === "success"
      } catch (err) {
        resetIntermediateAuthStates({
          loading: false,
          error: err?.message ?? err,
        })
        return false
      }
    },
    [resetIntermediateAuthStates],
  )

  /**
   * This function sets a new password to the associated user account.
   * @public @memberof {@link useIntegration}
   * @param {string} name The name of the user.
   * @param {string} password The password of the user to be set.
   * @param {string} token The auth token to set the new password.
   * @returns {Promise<boolean>} Returns a promise that resolves to **true** if the password is set successfully, **false** otherwise.
   */
  const setPassword = useCallback(
    async (name, password, token) => {
      try {
        resetIntermediateAuthStates({
          loading: true,
          error: "",
        })
        const res = await integrationAuthFinishAPI({
          name,
          password,
          token,
        })
        resetIntermediateAuthStates({
          loading: false,
          error: "",
        })
        return !!res
      } catch (err) {
        resetIntermediateAuthStates({
          loading: false,
          error: err?.data?.message ?? err,
        })
        return false
      }
    },
    [resetIntermediateAuthStates],
  )

  /**
   * This function is used to send a magic link to invite a user to the app.
   * @public @memberof {@link useIntegration}
   * @param {string} appId The id of the app.
   * @param {string} email_id The email of the user to be invited.
   * @param {string} role The role of the user to be invited. One of {@link ROLES_ENUM}.
   * @param {string[]} flags The flags of the user to be invited. One of {@link FLAGS_ENUM}.
   * @param {string[]} permissions The permissions of the user to be invited. One of {@link PERMISSIONS_ENUM}.
   * @returns {Promise<boolean>} Returns a promise that resolves to `true` if the magic link is sent successfully, `false` otherwise.
   */
  const sendInviteEmail = useCallback(
    async (appId, orgId, email_id, role, flags, permissions) => {
      try {
        resetIntermediateAppStates({
          loading: true,
          error: "",
        })
        const invitation_data = {
          email_id,
          role,
          flags,
          permissions,
        }
        const res = await inviteUserAPI(appId, orgId, invitation_data)
        resetIntermediateAppStates({
          loading: false,
          error: "",
        })
        return !!res
      } catch (err) {
        resetIntermediateAppStates({
          loading: false,
          error: err?.data?.message ?? err,
        })
        return false
      }
    },
    [resetIntermediateAppStates],
  )

  /**
   * This function is used to list all the users associated to an app.
   * @public @memberof {@link useIntegration}
   * @param {string} appId The id of the app.
   */
  const getInvitees = useCallback(
    async (appId) => {
      try {
        resetIntermediateAppStates({
          loading: true,
          error: "",
        })
        const res = await getAllInviteesAPI(appId)
        resetIntermediateAppStates({
          loading: false,
          error: "",
        })
        return res
      } catch (err) {
        resetIntermediateAppStates({
          loading: false,
          error: err?.data?.message ?? err,
        })
      }
    },
    [resetIntermediateAppStates],
  )

  /**
   * This function is used to edit the secondary details of a user.
   * @public @memberof {@link useIntegration}
   * @param {string} appId The id of the app.
   * @param {string} customer_id The email of the target user.
   * @param {string} role The role of the target user. One of {@link ROLES_ENUM}.
   * @param {string[]} flags The flags of the target user. One of {@link FLAGS_ENUM}.
   * @param {string[]} permissions The permissions of the target user. One of {@link PERMISSIONS_ENUM}.
   */
  const editUserSecondaryDetails = useDeepCompareCallback(
    async (appId, customer_id, role, flags, permissions) => {
      try {
        resetIntermediateAppStates({
          loading: true,
          error: "",
        })
        const res = await editUserPermissionsAPI(appId, {
          customer_id,
          role,
          flags,
          permissions,
        })
        resetIntermediateAppStates({
          loading: false,
          error: "",
        })
        return res
      } catch (err) {
        resetIntermediateAppStates({
          loading: false,
          error: err?.data?.message ?? err,
        })
      }
    },
    [auth, resetIntermediateAppStates],
  )

  /**
   * This function is used to revoke an invitation sent to a user from the app.
   * @public @memberof {@link useIntegration}
   * @returns {Promise<boolean>} Returns a promise that resolves to **true** if the invitation is revoked successfully, **false** otherwise.
   */
  const revokeInvitation = useCallback(
    async (appId, email) => {
      try {
        resetIntermediateAppStates({
          loading: true,
          error: "",
        })
        const res = await revokeInvitationAPI(appId, email)
        resetIntermediateAppStates({
          loading: false,
          error: "",
        })
        return !!res
      } catch (err) {
        resetIntermediateAppStates({
          loading: false,
          error: err?.data?.message ?? err,
        })
        return false
      }
    },
    [resetIntermediateAppStates],
  )

  /**
   * This function is used to create a new app in the organisation.
   * @public @memberof {@link useIntegration}
   * @param {string} app_name The name of the app.
   * @param {string} bundle_id The bundle id of the app.
   * @param {string} platform The platform of the app.
   * @param {string} orgId The ID of the organisation to which the app belongs.
   * @returns {Promise<string|null>} Returns a promise that resolves to the app's details.
   */
  const addNewApplication = useDeepCompareCallback(
    async (app_name, bundle_id, platform, orgId) => {
      try {
        resetIntermediateAppStates({
          loading: true,
          error: "",
        })
        const { app_id } = await addAppAPI(
          auth,
          {
            app_name,
            bundle_id,
            platform,
          },
          orgId,
        )
        resetIntermediateAppStates({
          loading: false,
          error: "",
        })
        return app_id
      } catch (err) {
        resetIntermediateAppStates({
          loading: false,
          error: err?.data?.message ?? err,
        })
        return null
      }
    },
    [auth, resetIntermediateAppStates],
  )

  /**
   * This function is used to delete an app from the organisation.
   * @public @memberof {@link useIntegration}
   * @param {string} appId The ID of the app to be deleted.
   * @returns {Promise<boolean>} Returns a promise that resolves to **true** if the app is deleted successfully, **false** otherwise.
   */
  const deleteApplication = useDeepCompareCallback(
    async (appId) => {
      try {
        resetIntermediateAppStates({
          loading: true,
          error: "",
        })
        const res = await deleteAppAPI(auth, appId)
        resetIntermediateAppStates({
          loading: false,
          error: "",
        })
        return !!res
      } catch (err) {
        resetIntermediateAppStates({
          loading: false,
          error: err?.data?.message ?? err,
        })
        return false
      }
    },
    [auth, resetIntermediateAppStates],
  )

  /**
   * This function is used to get the FCM key and Bundle ID of an app.
   * @public @memberof {@link useIntegration}
   * @param {string} appId The ID of the app.
   * @param {string} appName The name of the app.
   * @param {string} platform The platform of the app.
   * @returns {Promise<{bundleId: string}|{fcmKey: string, bundleId: string}|null>} Returns a promise that resolves to the FCM key and Bundle ID of the app.
   */
  const getFCMKeyAndBundleID = useDeepCompareCallback(
    async (appId, appName, platform) => {
      try {
        resetIntermediateAppStates({
          loading: true,
          error: "",
        })
        let promises = [
          getBundleIds(auth, appId),
          ...(platform === APP_PLATFORMS.android
            ? [getPushSettings(auth, appId)]
            : []),
        ]
        const responses = await Promise.all(promises)
        resetIntermediateAppStates({
          loading: false,
          error: "",
        })
        const bundleId =
          responses[0]?.filter((id) => id?.name === `${appName}-${platform}`)[0]
            ?.id ?? ""
        return {
          bundleId,
          ...(platform === APP_PLATFORMS.android && {
            fcmKey: responses[1]?.fcmKey,
          }),
        }
      } catch (err) {
        resetIntermediateAppStates({
          loading: false,
          error: err?.message ?? "App not found",
        })
      }
    },
    [auth, resetIntermediateAppStates],
  )

  /**
   * This function is used to set an invitee's invitation status to **accepted**.
   * @public @memberof {@link useIntegration}
   * @param {string} token - The token sent in the invitee's invitation magic link.
   * @returns {Promise<boolean>} Returns a promise that resolves to **true** if the invitation status is made truthy, **false** otherwise.
   */
  const makeUserStatusInvited = useCallback(
    async (token) => {
      try {
        resetIntermediateAppStates({
          loading: true,
          error: "",
        })
        await setInviteeStatusAPI(token, true)
        resetIntermediateAppStates({
          loading: false,
          error: "",
        })
        return true
      } catch (err) {
        resetIntermediateAppStates({
          loading: false,
          error: err?.data?.message ?? err,
        })
        return false
      }
    },
    [resetIntermediateAppStates],
  )

  /**
   * This function is used to set the `integrated` field of an app to `true`.
   * @public @memberof {@link useIntegration}
   * @param {string} appId - The id of the app whose `integrated` field is to be set to `true`.
   * @returns {Promise<boolean>} Returns a promise that resolves to **true** if the `integrated` field is set to `true`, **false** otherwise.
   */
  const setIntegrationStatusToTrue = useCallback(
    async (appId) => {
      try {
        resetIntermediateAppStates({
          loading: true,
          error: "",
        })
        await updateIntegrationStatusAPI(appId)
        resetIntermediateAppStates({
          loading: false,
          error: "",
        })
        return true
      } catch (err) {
        resetIntermediateAppStates({
          loading: false,
          error: err?.data?.message ?? err,
        })
        return false
      }
    },
    [resetIntermediateAppStates],
  )

  /**
   * This function is used to toggle the `monitoring` field of an app.
   * @public @memberof {@link useIntegration}
   * @param {string} appId - The id of the app whose `integrated` field is to be set to `true`.
   * @param {boolean} status - The current status of the monitoring of the app.
   * @returns {Promise<boolean>} Returns a promise that resolves to **true** if the `monitoring` field is toggled, **false** otherwise.
   */
  const updateMonitoringStatus = useDeepCompareCallback(
    async (appId, status) => {
      try {
        resetIntermediateAppStates({
          loading: true,
          error: "",
        })
        await setMonitoringStatusAPI(auth, appId, status)
        resetIntermediateAppStates({
          loading: false,
          error: "",
        })
        return true
      } catch (err) {
        resetIntermediateAppStates({
          loading: false,
          error: err?.data?.message ?? err,
        })
        return false
      }
    },
    [auth, resetIntermediateAppStates],
  )

  /**
   * This memoised value checks if the password is valid.
   * @public @memberof {@link useIntegration}
   * @returns {Array<boolean>} Returns an array of 4 boolean values which map to the checks in the enum {@link PASSWORD_CHECKS}
   */
  const isValidPassword = useMemo(() => {
    return [
      password?.length >= 8,
      password?.match(/[a-z]/) !== null,
      password?.match(/[A-Z]/) !== null,
      password?.match(/[0-9]/) !== null,
    ]
  }, [password])

  /**
   * This memoised value checks if **password** and **confirm_password** are matching.
   * @private @memberof {@link useIntegration}
   * @returns {boolean} **true** if passwords match, **false** otherwise.
   */
  const arePasswordsMatching = useMemo(() => {
    return password === confirm_password
  }, [password, confirm_password])

  /**
   * This memoised value checks if the submit button is disabled.
   * @public @memberof {@link useIntegration}
   * @returns {boolean} **true** if it is disabled, **false** otherwise.
   */
  const isSubmitDisabled = useDeepCompareMemo(() => {
    switch (pageType) {
      case AUTH_PAGE_TYPE.SIGN_IN:
        return !email || !password || !isValidEmail(email)
      case AUTH_PAGE_TYPE.DEV_SIGN_UP:
        return (
          [name, password, confirm_password, ...isValidPassword].some(
            (param) => !param,
          ) || !arePasswordsMatching
        )
      case AUTH_PAGE_TYPE.SIGN_UP:
        return (
          [name, email, job_title, company_name].some((param) => !param) ||
          !isValidEmail(email)
        )
      case AUTH_PAGE_TYPE.FORGOT_PASSWORD:
        return !email || !isValidEmail(email)
      case AUTH_PAGE_TYPE.RESET_PASSWORD:
      case AUTH_PAGE_TYPE.SET_PASSWORD:
        return (
          [password, confirm_password, ...isValidPassword].some(
            (param) => !param,
          ) || !arePasswordsMatching
        )
      default:
        return true
    }
  }, [
    arePasswordsMatching,
    company_name,
    confirm_password,
    email,
    isValidPassword,
    job_title,
    name,
    pageType,
    password,
  ])

  return {
    checkIfUserExists,
    checkIfInvitationIsRevoked,
    sendPasswordResetEmail,
    resetPassword,
    loginUser,
    sendSetPasswordEmail,
    setPassword,
    addNewApplication,
    deleteApplication,
    saveFCMKeyAndBundleID,
    sendInviteEmail,
    getInvitees,
    editUserSecondaryDetails,
    revokeInvitation,
    updateMonitoringStatus,
    makeUserStatusInvited,
    setIntegrationStatusToTrue,
    getFCMKeyAndBundleID,
    isSubmitDisabled,
    isValidPassword,
  }
}
