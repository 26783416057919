import { Box, Button, MuiThemeProvider, Typography } from "@material-ui/core"
import React from "react"
import { Link } from "react-router-dom"
import { apxTheme } from "../../ApplicationFrame/styling/CustomCSS"
import accessRevokedImg from "../../Auth/assets/access-revoked-404.svg"

export default function InvitationRevokedPage() {
  return (
    <MuiThemeProvider theme={apxTheme}>
      <Box
        style={{
          margin: "-8px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "16px",
            backgroundColor: "#002845",
            width: "100%",
          }}
        >
          <img
            src="/assets/img/logo-white.png"
            alt="Apxor ApxorLogo"
            height="30px"
          />
        </Box>
        <Box
          style={{
            minHeight: "calc(100vh - 200px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Typography
              style={{
                fontSize: "62px",
                fontWeight: "700",
              }}
            >
              Oops!
            </Typography>
            <Typography
              style={{
                fontSize: "28px",
                fontWeight: "600",
                marginTop: "2px",
              }}
            >
              Page Not Found
            </Typography>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: "500",
                opacity: "0.7",
                marginTop: "28px",
                maxWidth: "75%",
              }}
            >
              The link you followed is either broken or your access has been
              removed.
            </Typography>
            <Link
              to="/orgs"
              style={{
                textDecoration: "none",
                marginTop: "24px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
              >
                Go to Home
              </Button>
            </Link>
          </Box>
          <img
            src={accessRevokedImg}
            alt="Access Revoked"
            style={{
              width: "500px",
            }}
          />
        </Box>
      </Box>
    </MuiThemeProvider>
  )
}
