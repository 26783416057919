import { useCallback } from "react"
import { useHistory } from "react-router-dom"

export default function useMutateRouterState() {
  const {
    replace,
    location: { state },
  } = useHistory()

  const clearStateFields = useCallback(
    (fields) => {
      const stateCopy = {
        ...state,
      }
      fields.forEach((field) => {
        delete stateCopy[field]
      })
      replace({
        state: stateCopy,
      })
    },
    [replace, state],
  )

  return { clearStateFields }
}
