/**
 * @enum {string}
 */
export const AUTH_PAGE_TYPE = Object.freeze({
  SIGN_IN: "SIGN_IN",
  SIGN_UP: "SIGN_UP",
  DEV_SIGN_UP: "DEV_SIGN_UP",
  SET_PASSWORD: "SET_PASSWORD",
  EXPERIENCE_DASHBOARD: "EXPERIENCE_DASHBOARD",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
})

/**
 * @constant
 */
export const PASSWORD_CHECKS = Object.freeze([
  "Be a minimum of 8 characters",
  "Atleast one lower case letter (a-z)",
  "Atleast one upper case letter (A-Z)",
  "Atleast one number (0-9)",
])
