import { useCallback } from "react"
import { useAuth } from "../../../contexts/AuthContext"
import { useDeepCompareCallback } from "../../../utils/use-deep-compare"
import { approveAppAPI } from "../../Dashboard/components/approval/actions"
import { updateFeaturesListAPI } from "../../Dashboard/components/settings/general/actions"
import { fetchPendingAppApprovalsAPI } from "../../Profile/actions"
import {
  createNewOrgAPI,
  deleteOrgAPI,
  getAllOrgsAPI,
  getAppsFromOrgIdAPI,
  getOrgByIdAPI,
  updateOrgDetailsAPI,
} from "../actions"
import { SET_ORG_ERROR, SET_ORG_LOADING } from "../constants"
import { useDispatch } from "../store"

/**
 * A custom hook to use the organization functionality.
 */
export default function useOrganization() {
  const auth = useAuth()
  const dispatch = useDispatch()

  /**
   * @typedef {Object} ErrorLoadingStates
   * @property {boolean} loading - Whether the resource is loading
   * @property {boolean} error - Whether the resource has an error
   */

  /**
   * This function resets `org_loading` and `org_error` states.
   * @private @memberof {@link useOrganization}
   * @param {ErrorLoadingStates} states An object containing the loading and error states.
   */
  const resetIntermediateOrgStates = useCallback(
    ({ loading = false, error = "" }) => {
      dispatch({
        type: SET_ORG_LOADING,
        payload: loading,
      })
      dispatch({
        type: SET_ORG_ERROR,
        payload: error,
      })
    },
    [dispatch],
  )

  /**
   * Creates a new organization.
   * @public @memberof {@link useOrganization}
   * @param {string} name The name of the organization.
   * @returns {Promise<string|boolean>} A promise that resolves to the organization ID if successful, `false` if not.
   */
  const createNewOrg = useCallback(
    async (details) => {
      try {
        resetIntermediateOrgStates({
          loading: true,
          error: "",
        })
        const { org_id } = await createNewOrgAPI(details)
        resetIntermediateOrgStates({
          loading: false,
          error: "",
        })
        return org_id
      } catch (error) {
        resetIntermediateOrgStates({
          loading: false,
          error: error?.message,
        })
        return false
      }
    },
    [resetIntermediateOrgStates],
  )

  /**
   * Gets all the apps under an organization by ID.
   * @public @memberof {@link useOrganization}
   * @param {string} org_id The ID of the organization.
   * @returns {Promise<never[]|boolean>} A promise that resolves to the list of apps if successful, `[]` if not.
   */
  const getAppsOfOrg = useCallback(
    async (org_id) => {
      try {
        resetIntermediateOrgStates({
          loading: true,
          error: "",
        })
        const apps = await getAppsFromOrgIdAPI(org_id)
        resetIntermediateOrgStates({
          loading: false,
          error: "",
        })
        return apps
      } catch (error) {
        resetIntermediateOrgStates({
          loading: false,
          error: error?.message,
        })
        return []
      }
    },
    [resetIntermediateOrgStates],
  )

  const updateOrgFeaturesList = useDeepCompareCallback(
    async (features, appId) => {
      try {
        resetIntermediateOrgStates({
          loading: true,
          error: "",
        })
        const res = await updateFeaturesListAPI(auth, appId, {
          features,
        })
        resetIntermediateOrgStates({
          loading: false,
          error: "",
        })
        return res
      } catch (error) {
        resetIntermediateOrgStates({
          loading: false,
          error: error?.message,
        })
        return false
      }
    },
    [auth, resetIntermediateOrgStates],
  )

  /**
   * Gets all the organizations.
   * @public @memberof {@link useOrganization}
   * @returns {Promise<import("../actions").OrganizationDTO[]>} A promise that resolves to the list of organizations if successful, `[]` if not.
   */
  const getOrgs = useCallback(async () => {
    try {
      resetIntermediateOrgStates({
        loading: true,
        error: "",
      })
      const orgs = await getAllOrgsAPI()
      resetIntermediateOrgStates({
        loading: false,
        error: "",
      })
      return orgs
    } catch (error) {
      resetIntermediateOrgStates({
        loading: false,
        error: error?.message,
      })
      return []
    }
  }, [resetIntermediateOrgStates])

  /**
   * Gets an organization by ID.
   * @public @memberof {@link useOrganization}
   * @param {string} org_id The ID of the organization.
   * @returns {Promise<import("../actions").OrganizationDTO>} A promise that resolves to the organization details if successful, `{}` if not.
   */
  const getOrgByID = useCallback(
    async (org_id) => {
      try {
        resetIntermediateOrgStates({
          loading: true,
          error: "",
        })
        const org = await getOrgByIdAPI(org_id)
        resetIntermediateOrgStates({
          loading: false,
          error: "",
        })
        return org
      } catch (error) {
        resetIntermediateOrgStates({
          loading: false,
          error: error?.message,
        })
        return {}
      }
    },
    [resetIntermediateOrgStates],
  )

  /**
   * Updates the details of an organization.
   * @public @memberof {@link useOrganization}
   * @param {string} org_id The ID of the organization.
   * @param {import("../actions").OrganizationDTO} details The details of the organization.
   */
  const updateOrgDetails = useCallback(
    async (org_id, details) => {
      try {
        resetIntermediateOrgStates({
          loading: true,
          error: "",
        })
        const org = await updateOrgDetailsAPI(org_id, details)
        resetIntermediateOrgStates({
          loading: false,
          error: "",
        })
        return org
      } catch (error) {
        resetIntermediateOrgStates({
          loading: false,
          error: error?.message,
        })
        return {}
      }
    },
    [resetIntermediateOrgStates],
  )

  /**
   * Deletes an organization by ID.
   * @public @memberof {@link useOrganization}
   * @param {string} org_id The ID of the organization.
   * @returns {Promise<boolean>} A promise that resolves to `true` if the organization is deleted successfully, `false` if not.
   */
  const deleteOrg = useCallback(
    async (org_id) => {
      try {
        resetIntermediateOrgStates({
          loading: true,
          error: "",
        })
        const deleted = await deleteOrgAPI(org_id)
        resetIntermediateOrgStates({
          loading: false,
          error: "",
        })
        return deleted
      } catch (error) {
        resetIntermediateOrgStates({
          loading: false,
          error: error?.message,
        })
        return false
      }
    },
    [resetIntermediateOrgStates],
  )

  const getPendingApps = useDeepCompareCallback(async () => {
    try {
      resetIntermediateOrgStates({
        loading: true,
        error: "",
      })
      const pendingApps = await fetchPendingAppApprovalsAPI(auth)
      resetIntermediateOrgStates({
        loading: false,
        error: "",
      })
      return pendingApps
    } catch (error) {
      resetIntermediateOrgStates({
        loading: false,
        error: error?.message,
      })
      return []
    }
  }, [auth, resetIntermediateOrgStates])

  const approveApp = useDeepCompareCallback(
    async (appId) => {
      try {
        resetIntermediateOrgStates({
          loading: true,
          error: "",
        })
        const approved = await approveAppAPI(auth, appId)
        resetIntermediateOrgStates({
          loading: false,
          error: "",
        })
        return approved
      } catch (error) {
        resetIntermediateOrgStates({
          loading: false,
          error: error?.message,
        })
        return false
      }
    },
    [auth, resetIntermediateOrgStates],
  )

  return {
    createNewOrg,
    getAppsOfOrg,
    getOrgs,
    getOrgByID,
    updateOrgFeaturesList,
    updateOrgDetails,
    deleteOrg,
    getPendingApps,
    approveApp,
  }
}
