import {
  makeDeleteRequest,
  makeGetRequest,
  makePostRequest,
  makePutRequest,
} from "../../../../common/actions"

const ART_CONFIGS = "art-configs"
const ART_CONFIG = "art-config"
const TAGS = "tags"

export function saveCampaignAPI(auth, appId, queryParams, postBody) {
  const onSuccessCallback = (response) => {
    return response
  }

  const onFailureCallback = (response) => {
    return {}
  }
  return makePostRequest(
    ART_CONFIGS,
    auth,
    appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback,
  )
}

export function updateCampaignAPI(
  auth,
  appId,
  queryParams,
  postBody,
  campaignId,
) {
  const onSuccessCallback = (response) => {
    return response
  }

  const onFailureCallback = (response) => {
    return {}
  }
  return makePutRequest(
    `${ART_CONFIGS}/${campaignId}`,
    auth,
    appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback,
  )
}

export function sendTestConfigToSDK(auth, appId, queryParams = {}, postBody) {
  const onSuccessCallback = (response) => {
    return response
  }

  const onFailureCallback = (response) => {
    return {}
  }

  return makePostRequest(
    ART_CONFIG,
    auth,
    appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback,
    window.SSE_ROOT,
  )
}

export function getTagsAPI(auth, appId, configType) {
  const onSuccessCallback = (json) => {
    return Array.isArray(json) ? json : []
  }
  const onFailureCallback = (response) => {
    return []
  }
  return makeGetRequest(
    TAGS,
    auth,
    appId,
    { configType },
    onSuccessCallback,
    onFailureCallback,
  )
}

/**
 * @typedef {Object} CampaignNameExistsQueryParams
 * @property {string} configType - `walkthrough` or `survey`
 *
 * @typedef {Object} CampaignNameExistsBody
 * @property {string} name - name of the campaign
 *
 * This API checks if the campaign name is already taken or not
 * @param {Object} auth The auth object
 * @param {string} appId The app id
 * @param {CampaignNameExistsQueryParams} queryParams The query params
 * @param {CampaignNameExistsBody} postBody The post body
 * @returns {Promise<boolean>} A promise that resolves to `true` if the name is already taken, `false` otherwise.
 */
export function checkIfCampaignNameExistsAPI(
  auth,
  appId,
  queryParams,
  postBody,
) {
  const onSuccessCallback = (bool) => {
    return bool
  }

  const onFailureCallback = (response) => {
    return response
  }
  return makePostRequest(
    ART_CONFIGS + "/exists",
    auth,
    appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback,
  )
}

/**
 * An API to check for conflicting campaigns.
 * @param {Object} auth The auth object.
 * @param {string} appId The ID of the app.
 * @param {Object} queryParams The query params.
 * @returns {Promise<Array>} A promise that resolves to an array of conflicting campaigns.
 */
export function checkForConflictsAPI(auth, appId, queryParams = {}) {
  const onSuccessCallback = (json) => {
    return Array.isArray(json) ? json : []
  }
  const onFailureCallback = (response) => {
    return []
  }
  return makeGetRequest(
    `${ART_CONFIGS}/conflict-check`,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback,
  )
}

// An API to save the template
export function saveTemplate(auth, appId, postBody) {
  const onSuccessCallback = (response) => {
    return response
  }

  const onFailureCallback = (response) => {
    return response?.message ?? "An error occurred while saving the template."
  }

  return makePostRequest(
    `${ART_CONFIGS}/template`,
    auth,
    appId,
    null,
    postBody,
    onSuccessCallback,
    onFailureCallback,
  )
}

// An API to get all the saved templates
export function getSavedTemplates(auth, appId, configType, platform) {
  const onSuccessCallback = (response) => {
    return response
  }

  const onFailureCallback = (response) => {
    return {}
  }
  return makeGetRequest(
    `${ART_CONFIGS}/templates`,
    auth,
    appId,
    { configType, platform },
    onSuccessCallback,
    onFailureCallback,
  )
}

// An API to delete the template
export function deleteTemplate(auth, appId, templateId, configType) {
  const onSuccessCallback = (response) => {
    return response
  }

  const onFailureCallback = (response) => {
    return {}
  }
  return makeDeleteRequest(
    `${ART_CONFIGS}/template/${templateId}`,
    auth,
    appId,
    { configType },
    onSuccessCallback,
    onFailureCallback,
  )
}

// An API to get the saved template
export function getSavedTemplate(auth, appId, templateId, configType) {
  const onSuccessCallback = (response) => {
    return response
  }

  const onFailureCallback = (response) => {
    return {}
  }
  return makeGetRequest(
    `${ART_CONFIGS}/template/${templateId}`,
    auth,
    appId,
    { configType },
    onSuccessCallback,
    onFailureCallback,
  )
}
