import { createTheme } from "@material-ui/core/styles"
import { isExperienceDashboard } from "../../../config"
import ReactDatesStyles from "../../../styling/ReactDatesStyles"
import { FONT_FAMILY_BOLD, FONT_FAMILY_REGULAR } from "../../../utils"

const customShadow =
  "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);" //'0 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 0px 3px 0px rgba(0, 0, 0, 0.14), 0px 0px 3px -1px rgba(0, 0, 0, 0.12)';

const BACKGROUND = "#f9f9f9" //"#e9e9ff";
const PAPER = "#FFFFFF"
const TEXT = "0,40,69"
const HEADER = "#002845"
const primary_color = "255,127,51" // 255,127,51
export const PRIMARY = {
  main: `rgba(${primary_color}, 1)`,
  secondary: BACKGROUND,
  paper: PAPER,
  header: HEADER,
  disabled: `rgba(${primary_color}, 0.78)`,
  hint: `rgba(${primary_color}, 0.5)`,
  almostInvisible: `rgba(${primary_color}, 0.06)`,
}

export const PALETTE = {
  background: {
    default: BACKGROUND,
    paper: PAPER,
    disabled: `rgba(${TEXT}, 0.2)`,
  },
  primary: PRIMARY,
  secondary: {
    main: "#ffffff",
  },
  error: {
    main: "#f05165",
  },
  text: {
    full: `rgba(${TEXT}, 1)`,
    primary: `rgba(${TEXT}, 0.87)`,
    secondary: `rgba(${TEXT}, 0.54)`,
    disabled: `rgba(${TEXT}, 0.38)`,
    hint: `rgba(${TEXT}, 0.38)`,
    light: `rgba(${TEXT}, 0.2)`,
    lightest: `rgba(${TEXT}, 0.02)`,
  },
  border: {
    primary: PRIMARY.main,
    secondary: "#EFF2F3",
    disabled: `rgba(${TEXT}, 0.38)`,
  },
  action: {
    full: `rgba(${TEXT}, 1)`,
    primary: `rgba(${TEXT}, 0.87)`,
    secondary: `rgba(${TEXT}, 0.54)`,
    disabled: `rgba(${TEXT}, 0.38)`,
    hint: `rgba(${TEXT}, 0.38)`,
  },
}

export const SIDEBAR = {
  ...PALETTE,
  primary: {
    main: "rgba(255,127,51, 1)",
  },
  secondary: {
    main: "#fff",
  },
}

const fontConfig = (fontSize, fontFamily = FONT_FAMILY_BOLD, color) => ({
  fontSize,
  fontFamily,
  color: PALETTE.text.primary,
  textTransform: "none",
})

export const apxTheme = createTheme({
  typography: {
    fontFamily: FONT_FAMILY_REGULAR,
    h4: fontConfig(24),
    h5: {
      ...fontConfig(18),
      "&$noWrap": {
        fontWeight: 600,
      },
    },
    h6: fontConfig(16),
    subtitle1: fontConfig(14),
    subtitle2: fontConfig(14),
    caption: fontConfig(12),
    body1: fontConfig("0.875rem", FONT_FAMILY_REGULAR), //18
    body2: {
      ...fontConfig(16, FONT_FAMILY_REGULAR),
      color: PALETTE.text.secondary,
    },
  },
  palette: {
    ...PALETTE,
  },
  sidebar: {
    ...SIDEBAR,
  },
  custom: {
    shadow: customShadow,
  },
  shadows: ["none", customShadow],
  overrides: {
    MuiAutocomplete: {
      endAdornment: {
        "& button": {
          "& span": {
            "& svg": {
              color: "#8497A5",
            },
          },
        },
      },
      groupLabel: {
        color: PALETTE.text.primary,
        fontWeight: 800,
      },
    },
    MuiButton: {
      containedPrimary: {
        color: PALETTE.secondary.main,
      },
      label: {
        fontWeight: 700,
      },
      root: {
        textTransform: "none",
        borderRadius: 12,
      },
      startIcon: {
        strokeWidth: 1.5,
      },
      endIcon: {
        strokeWidth: 1.5,
      },
    },
    MuiBottomNavigationAction: {
      root: {
        "&$selected": {
          paddingTop: 12,
          fontSize: "0.75rem !important",
        },
      },
    },
    MuiCard: {
      root: {
        overflow: "inherit",
      },
    },
    MuiCheckbox: {
      root: {
        color: PALETTE.text.secondary,
        "&$checked": {
          color: PALETTE.text.primary,
        },
      },
      colorPrimary: {
        color: PALETTE.text.secondary,
        "&$checked": {
          color: PALETTE.text.primary,
        },
      },
      checked: {},
    },
    MuiChip: {
      root: {
        color: PALETTE.text.primary,
        borderRadius: 3,
      },
    },
    MuiCollapse: {
      container: {
        overflow: "initial !important",
        display: "none",
      },
      entered: {
        display: "block",
      },
    },
    MuiExpansionPanel: {
      root: {
        "&$expanded": {
          margin: 0,
        },
      },
    },
    MuiExpansionPanelSummary: {
      expandIcon: {
        padding: 0,
      },
    },
    MuiFab: {
      primary: {
        color: PALETTE.secondary.main,
      },
    },
    MuiFormLabel: {
      root: {
        fontWeight: 600,
        top: "0px !important",
        left: "0px !important",
        "&$focused": {
          color: PALETTE.text.primary,
          top: "0px !important",
          left: "0px !important",
        },
        "&$filled": {
          top: "0px !important",
          left: "0px !important",
        },
      },
    },
    MuiIconButton: {
      root: {
        color: PALETTE.error.main,
        fontWeight: 600,
        fontSize: 12,
      },
    },
    MuiInput: {
      root: {
        border: `1px solid #CCD4DA`,
        borderRadius: 12,
        backgroundColor: PALETTE.secondary.main,
      },
      input: {
        padding: 10,
        "&::placeholder": {
          color: PALETTE.action.full,
        },
      },
      underline: {
        "&&&&:after": {
          borderBottom: `0px solid`,
        },
        "&&&&:before": {
          borderBottom: `0px solid`,
        },
        "&&&&:hover:before": {
          borderBottom: `0px solid`,
        },
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: PALETTE.secondary.main,
      },
      adornedStart: {
        "& svg": {
          color: PALETTE.text.hint,
        },
      },
      adornedEnd: {
        "& svg": {
          color: PALETTE.text.hint,
        },
      },
    },
    MuiInputLabel: {
      shrink: {
        fontSize: 16,
        fontWeight: 900,
      },
    },
    MuiListItem: {
      root: {
        color: PALETTE.primary.main,
        "&&&&:hover": {
          backgroundColor: PALETTE.primary.almostInvisible,
          borderLeft: `2px solid ${PALETTE.primary.main}`,
        },
        "&$selected": {
          backgroundColor: PALETTE.primary.almostInvisible,
          borderLeft: `2px solid ${PALETTE.primary.main}`,
        },
      },
    },
    MuiListItemIcon: {
      root: {
        color: PALETTE.primary.main,
        minWidth: "30px !important",
      },
    },
    MuiListItemText: {
      root: {
        color: PALETTE.primary.main,
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: PALETTE.text.secondary,
      },
      root: {
        borderRadius: 12,
      },
    },
    MuiPaper: {
      /*shadow2: {
                boxShadow: customShadow
            }*/
      root: {
        color: PALETTE.text.secondary,
        background: "none",
        borderRadius: 0,
      },
    },
    MuiPickersClockNumber: {
      clockNumberSelected: {
        color: PALETTE.secondary.main,
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: PALETTE.secondary.main,
      },
    },
    MuiPickersDay: {
      daySelected: {
        color: PALETTE.secondary.main,
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: 6,
        boxShadow: "0px 5px 30px rgba(181, 202, 224, 0.6)",
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          borderRadius: 12,
        },
      },
    },
    MuiStepIcon: {
      text: {
        fill: `${PALETTE.secondary.main} !important`,
      },
      completed: {
        color: `${PALETTE.primary.hint} !important`,
      },
    },
    MuiSvgIcon: {
      fontSizeLarge: {
        fontSize: "3.1875rem",
      },
    },
    MuiTab: {
      root: {
        fontSize: 15,
        fontWeight: 600,
      },
      textColorSecondary: {
        color: PALETTE.text.secondary,
        fontWeight: 600,
        fontSize: 16,
        textTransform: "capitalize",
        "&$selected": {
          color: PALETTE.text.primary,
        },
      },
      textColorInherit: {
        color: PALETTE.text.secondary,
        "&$selected": {
          color: PALETTE.text.primary,
        },
      },
    },
    MuiTabs: {
      root: {
        backgroundColor: "transparent !important",
      },
      flexContainer: {
        backgroundColor: "transparent !important",
      },
      indicator: {
        backgroundColor: PALETTE.primary.main,
        height: 3,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
      },
    },
    MuiToggleButton: {
      root: {
        borderColor: "#CCD4DA",
        "&&&&:hover": {
          backgroundColor: PALETTE.text.secondary,
          color: PALETTE.secondary.main,
        },
        "&$selected": {
          color: PALETTE.secondary.main,
          backgroundColor: PALETTE.text.primary,
          border: "none",
          "&&&&:hover": {
            backgroundColor: PALETTE.text.primary,
            color: PALETTE.secondary.main,
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      root: {
        borderRadius: 2,
      },
    },
  },
})

export const globalStyles = (theme) => ({
  "@global": {
    html: {
      background: PALETTE.background.default,
      //background: theme.palette.background.default, //FIXME: not working
      WebkitFontSmoothing: "antialiased", // Antialiasing.
      MozOsxFontSmoothing: "grayscale", // Antialiasing.
      boxSizing: "border-box",
      "@media print": {
        background: PALETTE.background.default,
        //background: theme.palette.background.default //FIXME: theme not applying
      },
    },
    "*, *:before, *:after": {
      boxSizing: "inherit",
    },
    body: {
      margin: 0,
      height: "100%",
      fontFamily: "Manrope",
      letterSpacing: "0.01em",
    },
    "section.content": {
      padding: "15px 30px 30px 30px",
      height: "auto",
    },
    a: {
      textDecoration: "none !important",
      color: PALETTE.primary.main,
    },
    "::-webkit-scrollbar": {
      width: "16px",
      transition: "all 0.2s ease-in-out",
    },
    "::-webkit-scrollbar-thumb": {
      border: "5px solid rgba(0, 0, 0, 0)",
      "background-clip": "padding-box",
      "border-radius": "9999px",
      "background-color": "#CBD1D5",
    },
    ...ReactDatesStyles(apxTheme),
    ".highcharts-credits": { display: "none" },
    ".recharts-default-tooltip": {
      //Tweak for recharts tooltip
      background: "#ffffff !important", //'linear-gradient(45deg, rgba(254, 254, 254, 0.54) 30%, rgba(255, 142, 83, 0.56) 90%) !important'
      fontSize: 16,
      fontWeight: 600,
    },
    ".public_fixedDataTable_main": {
      color: PALETTE.text.primary,
    },
    ".item-center": {
      margin: "0 auto",
    },
    ".input-range__track--active": {
      backgroundColor: PALETTE.primary.disabled,
    },
    ".input-range__slider": {
      backgroundColor: PALETTE.primary.main,
      border: `1px solid ${PALETTE.primary.main}`,
    },
    ".MuiMenuItem-root > a": {
      color: PALETTE.text.primary,
    },
    ".MuiMenuItem-root > div": {
      minWidth: "unset !important",
    },
    text: {
      fontFamily: FONT_FAMILY_REGULAR,
    },
    textarea: {
      minHeight: "0px !important",
      height: "auto",
    },
  },
  root: {
    width: "100%",
    height: "100%",
    //marginTop: apxTheme.spacing.unit * 3,
    zIndex: 1,
    overflow: "hidden",
    flexGrow: 1,
    position: "relative",
    display: "flex",
  },
  appFrame: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  // progress: {
  //   width: "100%",
  //   position: "fixed",
  //   zIndex: 3000,
  //   height: theme.spacing(1 / 2),
  //   top: 0,
  // },
  content: {
    width: "100%",
    flexGrow: 1,
    background: BACKGROUND, // theme.palette.background.default,
    //padding: 15,
    marginTop: isExperienceDashboard() ? "56px" : "20px",
    minHeight: "100vh",
    marginLeft: 100,
    overflow: "hidden",
  },
  pinnedContent: {
    marginLeft: drawerWidth + 10,
  },
})

const drawerWidth = 260
const shortNavWidth = 100

export const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    //marginTop: apxTheme.spacing.unit * 3,
    zIndex: 1,
    overflow: "hidden",
    flexGrow: 1,
    position: "relative",
    display: "flex",
  },
  appFrame: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  logoAvatar: {
    marginLeft: 45,
    width: 40,
    height: 40,
  },
  appBar: {
    zIndex: 10001, //FIXME:
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: theme.palette.background.paper, //'white'
  },
  onDashboard: {
    width: `calc(100% - ${shortNavWidth}px)`,
    marginLeft: shortNavWidth,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    //left: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
    color: "#333333",
  },
  hide: {
    display: "none",
  },
  drawerPaper: {
    position: "relative",
    height: "100%",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    width: 60,
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerInner: {
    // Make the items inside not wrap when transitioning:
    width: drawerWidth,
  },
  heading: {
    marginLeft: theme.spacing(4),
    color: "#333333",
  },
})
