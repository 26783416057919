import { Grid, Typography } from "@material-ui/core"
import { useQuery } from "@tanstack/react-query"
import { apxTheme } from "../../ApplicationFrame/styling/CustomCSS"
import useOrganization from "../hooks/use-organization"
import OrgCard, { OrgCardSkeleton } from "./OrgCard"

export function OrgsGridSkeleton() {
  return (
    <Grid
      container
      spacing={2}
    >
      {[...Array(9)].map((_, idx) => (
        <Grid
          item
          xs={4}
          key={idx}
        >
          <OrgCardSkeleton />
        </Grid>
      ))}
    </Grid>
  )
}

export default function OrgsGrid({ searchText }) {
  const { getOrgs } = useOrganization()

  const { data } = useQuery({
    queryFn: async () => {
      const res = await getOrgs()
      return res
    },
    queryKey: ["orgs"],
    suspense: true,
  })

  const filteredOrgs = data?.filter((org) =>
    org?.name.toLowerCase().includes(searchText?.toLowerCase()),
  )

  return (
    <Grid
      container
      spacing={2}
    >
      {filteredOrgs?.length > 0 ? (
        filteredOrgs.map((org, idx) => (
          <Grid
            item
            xs={4}
            key={idx}
          >
            <OrgCard org={org} />
          </Grid>
        ))
      ) : (
        <Grid
          xs={12}
          container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <Typography
            style={{
              color: apxTheme.palette.text.primary,
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            No Organisations Found!
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}
