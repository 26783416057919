import { Box, Button, Typography } from "@material-ui/core"
import React, { useMemo } from "react"
import { Link, useLocation, useParams } from "react-router-dom"
import { capitalizeEachWord } from "../../../../utils"
import useMutateRouterState from "../../../../utils/use-mutate-router-state"
import { apxTheme } from "../../../ApplicationFrame/styling/CustomCSS"
import ColumnDiv from "../../../ReusableComponents/ColumnDiv"
import RowDiv from "../../../ReusableComponents/RowDiv"
import IntegrationLayout from "../../IntegrationLayout"
import { useTrackedState } from "../../store"

export default function IntegrationFinish() {
  const { app_data } = useTrackedState()
  const { orgId, appId } = useParams()
  const { state } = useLocation()
  const { clearStateFields } = useMutateRouterState()

  const appIcon = useMemo(
    () => app_data?.basic_info?.icon_url ?? "",
    [app_data],
  )
  const appName = useMemo(
    () =>
      app_data?.basic_info?.app_name
        ?.split(" ")
        .map((word) => capitalizeEachWord(word))
        .map((word) => word[0])
        .slice(0, 2)
        .join("") ?? "",
    [app_data],
  )

  return (
    <IntegrationLayout>
      <ColumnDiv
        styles={{
          minHeight: "100%",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "450px",
          marginInline: "auto",
        }}
      >
        <RowDiv
          center
          styles={{ marginBottom: "14px" }}
        >
          <Box
            style={{
              backgroundColor: apxTheme.palette.text.primary,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              width: "100px",
              height: "100px",
            }}
          >
            <img
              src="/assets/img/sidebar_logo.png"
              alt="Apxor Logo"
              style={{
                width: "80px",
                height: "80px",
              }}
            />
          </Box>
          <Box
            style={{
              marginLeft: "-10px",
              backgroundColor:
                appIcon && appIcon !== "/assets/img/playStore.ico"
                  ? "#F9D9C7"
                  : apxTheme.palette.primary.main,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              width: "100px",
              height: "100px",
            }}
          >
            {appIcon && appIcon !== "/assets/img/playStore.ico" ? (
              <img
                src={appIcon}
                alt="Apxor Logo"
                style={{
                  maxWidth: "50px",
                  maxHeight: "50px",
                }}
              />
            ) : (
              <Typography
                style={{
                  color: "white",
                  fontSize: "40px",
                  fontWeight: "600",
                }}
              >
                {appName}
              </Typography>
            )}
          </Box>
        </RowDiv>
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "700",
            marginBottom: "12px",
          }}
        >
          Set up complete!
        </Typography>
        <Typography
          style={{
            fontSize: "13px",
            fontWeight: "500",
            color: "#667A8A",
            opacity: "0.9",
            marginBottom: "35px",
            textAlign: "center",
          }}
        >
          Congratulations on successfully completing the setup for your first
          application! You can now click on <b>Go to Dashboard</b> and create
          your first campaign or survey and publish it to your users.
        </Typography>
        <Link
          to={`/orgs/${orgId}/apps/${appId}/dashboard`}
          component={Button}
          variant="contained"
          size="large"
          color="primary"
          style={{
            textDecoration: "none",
            color: "white",
          }}
          onClick={() => {
            if (state?.fromTestConnection) {
              clearStateFields(["fromTestConnection"])
            }
          }}
        >
          Go to Dashboard
        </Link>
      </ColumnDiv>
    </IntegrationLayout>
  )
}
