import {
  Button,
  Checkbox,
  FormControlLabel,
  Input,
  Link,
  Typography,
} from "@material-ui/core"
import { useCallback, useEffect, useState } from "react"
import { Link as RouterLink, useHistory } from "react-router-dom"
import useApxorClient from "../../../../utils/use-apxor-client"
import useMutateRouterState from "../../../../utils/use-mutate-router-state"
import { apxTheme } from "../../../ApplicationFrame/styling/CustomCSS"
import useOrganization from "../../../Organizations/hooks/use-organization"
import HStack from "../../../ReusableComponents/HStack"
import Loading from "../../../ReusableComponents/Loading"
import { SET_AUTH_ERROR, SET_EMAIL_ID, SET_PASSWORD } from "../../constants"
import { useDispatch, useTrackedState } from "../../store"
import PasswordInput from "../components/PasswordInput"
import useIntegration from "../hooks/use-integration"
import AuthLayout from "../layouts/AuthLayout"

export default function SignInPage() {
  const { getOrgs, getAppsOfOrg } = useOrganization()
  const { loginUser, makeUserStatusInvited, isSubmitDisabled } =
    useIntegration()
  const state = useTrackedState()
  const dispatch = useDispatch()
  const {
    push,
    location: { state: routerState },
  } = useHistory()
  const { clearStateFields } = useMutateRouterState()
  const { logEvent } = useApxorClient()
  const [logCount, setlogCount] = useState(0)

  useEffect(() => {
    if (logCount === 0) {
      logEvent("LoginPageLaunched")
      setlogCount((logCount) => logCount + 1)
    }
  }, [logCount, logEvent])

  const {
    user: { email, password },
    auth_error,
    auth_loading,
  } = state

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault()
      logEvent("SignInClicked")
      if (await loginUser(email, password)) {
        if (routerState?.setInvitedToTrue) {
          await makeUserStatusInvited(routerState?.token)
          clearStateFields(["setInvitedToTrue", "token"])
        }
        logEvent("LoginStatus", { Status: true })

        const orgs = await getOrgs()

        if (orgs?.length === 1) {
          const apps = await getAppsOfOrg(orgs?.[0]?._id)
          const filteredApps = apps?.filter((app) =>
            app?.basic_info?.customers?.some(
              ({ customer_id }) => customer_id === email,
            ),
          )

          if (filteredApps?.length === 1) {
            push(
              `/orgs/${orgs?.[0]?._id}/apps/${filteredApps?.[0]?.app_id}/dashboard`,
            )
          } else if (filteredApps?.length > 1) {
            push(`/orgs/${orgs?.[0]?._id}/apps`, {
              from_login: true,
            })
          }
        } else {
          push("/orgs", {
            from_login: true,
          })
        }
      } else {
        logEvent("LoginStatus", { Status: false })
      }
    },
    [
      clearStateFields,
      email,
      getAppsOfOrg,
      getOrgs,
      logEvent,
      loginUser,
      makeUserStatusInvited,
      password,
      push,
      routerState?.setInvitedToTrue,
      routerState?.token,
    ],
  )

  return (
    <AuthLayout title="Sign In">
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "start",
          marginRight: "24px",
          flex: "1",
        }}
      >
        <label
          htmlFor="email"
          style={{
            marginTop: "16px",
            fontSize: "13px",
            fontWeight: "500",
            color: "#667A8A",
          }}
        >
          Email ID
        </label>
        <Input
          name="email"
          size="medium"
          id="email"
          type="email"
          value={email}
          defaultValue={email}
          onChange={(e) => {
            dispatch({
              type: SET_EMAIL_ID,
              payload: e.target.value,
            })
            if (auth_error?.length > 0) {
              dispatch({
                type: SET_AUTH_ERROR,
                payload: "",
              })
            }
          }}
          style={{
            width: "60%",
            marginTop: "4px",
            border: `1px solid ${apxTheme.palette.action.selected}`,
            fontWeight: "600",
            fontSize: "14px",
            padding: "4.5px",
          }}
        />
        <label
          htmlFor="password"
          style={{
            marginTop: "16px",
            fontSize: "13px",
            fontWeight: "500",
            color: "#667A8A",
          }}
        >
          Password
        </label>
        <PasswordInput
          name="password"
          id="password"
          value={password}
          onChange={(e) => {
            dispatch({
              type: SET_PASSWORD,
              payload: e.target.value,
            })
            if (auth_error?.length > 0) {
              dispatch({
                type: SET_AUTH_ERROR,
                payload: "",
              })
            }
          }}
          style={{
            width: "60%",
            marginTop: "4px",
            border: `1px solid ${apxTheme.palette.action.selected}`,
            fontWeight: "600",
            fontSize: "14px",
            padding: "4.5px",
            marginBottom: "10px",
          }}
        />
        <HStack
          justify="space-between"
          align="center"
          style={{
            width: "60%",
            marginBottom: "45px",
          }}
        >
          <FormControlLabel
            value="end"
            control={<Checkbox color="primary" />}
            label={
              <Typography
                style={{
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                Remember Me
              </Typography>
            }
            labelPlacement="end"
            style={{
              fontSize: "13px",
              fontWeight: "500",
            }}
          />
          <Link
            component={RouterLink}
            to="/forgot-password"
            style={{
              color: apxTheme.palette.primary.main,
              fontSize: "13px",
              fontWeight: "500",
            }}
            onClick={() => {
              logEvent("ForgotPasswordClicked")
              dispatch({
                type: SET_AUTH_ERROR,
                payload: "",
              })
            }}
          >
            Forgot Password?
          </Link>
        </HStack>
        <HStack
          style={{
            marginBottom: "12px",
            width: auth_error ? "100%" : "60%",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            disabled={isSubmitDisabled || auth_error !== ""}
            style={{
              width: auth_error ? "30%" : "50%",
              height: 48,
            }}
          >
            {auth_loading ? (
              <Loading
                size={28}
                color="#FFFFFF"
              />
            ) : (
              "Sign In"
            )}
          </Button>
          <span
            style={{
              marginLeft: "12px",
              fontSize: "16px",
              fontWeight: "500",
              color: "#F12D2D",
            }}
          >
            {auth_error !== "" && auth_error}
          </span>
        </HStack>
        <Typography
          style={{
            fontSize: "13px",
            fontWeight: "600",
            color: "#4D697D",
          }}
        >
          Don&apos;t have an account?{" "}
          <Link
            component={RouterLink}
            to="/sign_up"
            style={{
              color: apxTheme.palette.primary.main,
            }}
          >
            Sign Up
          </Link>
        </Typography>
      </form>
    </AuthLayout>
  )
}
