export const APP_MONITORING_ENUM = Object.freeze({
  ACTIVE: {
    value: true,
    label: "Active",
    glassColor: "rgba(70, 151, 2, 0.5)",
    backgroundColor: "#E5F1DB",
    color: "#469702",
  },
  INACTIVE: {
    value: false,
    label: "InActive",
    glassColor: "rgba(103, 122, 135, 0.5)",
    backgroundColor: "rgba(103, 122, 135, 0.1)",
    color: "#677A87",
  },
})

/**
 * @enum {string}
 */
export const APP_DIALOG_STATES_ENUM = Object.freeze({
  CLOSE: "",
  DELETE: "delete",
  MONITORING: "monitoring",
})

/**
 * @enum {string}
 */
export const ORG_DIALOG_STATES_ENUM = Object.freeze({
  CLOSE: "",
  DELETE: "delete",
  EDIT: "edit",
})

/**
 * @enum {string}
 */
export const USER_DIALOG_STATES_ENUM = Object.freeze({
  CLOSE: "",
  VIEW: "view",
  NEW: "new",
  EDIT: "edit",
  DELETE: "delete",
})
