import produce from "immer"
import { SET_ORG_DATA, SET_ORG_ERROR, SET_ORG_LOADING } from "./constants"

export const initial_state = {
  org_loading: false,
  org_error: "",
  org_data: {
    _id: "",
    owner: "",
    name: "",
  },
}

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case SET_ORG_LOADING:
      draft.org_loading = action.payload
      break
    case SET_ORG_ERROR:
      draft.org_error = action.payload
      break
    case SET_ORG_DATA:
      draft.org_data = action.payload
      break
    default:
      break
  }
})
