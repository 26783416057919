import {
  AppBar,
  MuiThemeProvider,
  Toolbar,
  Typography,
} from "@material-ui/core"
import React from "react"
import { apxTheme } from "../ApplicationFrame/styling/CustomCSS"
import ColumnDiv from "../ReusableComponents/ColumnDiv"
import { ReactComponent as DesktopOrLaptopOnlyImg } from "./assets/desktop-or-laptop-only.svg"

export default function DesktopOnlyPage() {
  return (
    <MuiThemeProvider theme={apxTheme}>
      <AppBar
        elevation={0}
        color="secondary"
      >
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#002845",
          }}
        >
          <img
            src="/assets/img/logo-white.png"
            alt="Apxor ApxorLogo"
            height="30px"
          />
        </Toolbar>
      </AppBar>
      <ColumnDiv
        styles={{
          height: "100vh",
          textAlign: "center",
          gap: "30px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DesktopOrLaptopOnlyImg />
        <ColumnDiv
          styles={{
            gap: "4px",
          }}
        >
          <Typography
            style={{
              fontWeight: "700",
              fontSize: "18px",
            }}
          >
            Open in Desktop/Laptop
          </Typography>
          <Typography
            style={{
              fontWeight: "500",
              fontSize: "13px",
              opacity: "0.8",
            }}
          >
            We don&apos;t support this site in mobile, please open it in a
            desktop browser.
          </Typography>
        </ColumnDiv>
      </ColumnDiv>
    </MuiThemeProvider>
  )
}
