/**
 * Resource: //quirksmode.org/js/cookies.html
 */
export default class Cookie {
  /**
   *
   * @param window
   * @param logger
   */
  constructor(window = {}, logger) {
    this.window = window
    this.logger = logger
    //this.cookie = window.document.cookie;
  }

  /**
   *
   * @param name
   * @returns {*}
   */
  get = (name) => {
    if (this.window.hasOwnProperty("document")) {
      let nameEQ = name + "="
      const ca = this.window.document.cookie.split(";")
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === " ") {
          c = c.substring(1, c.length)
        }
        if (c.indexOf(nameEQ) === 0) {
          return decodeURIComponent(c.substring(nameEQ.length, c.length))
        }
      }
    }
    return null
  }

  /**
   *
   * @param name
   * @returns {*}
   */
  getParsed = (name) => {
    let cookie
    try {
      cookie = JSON.parse(this.get(name)) || null
    } catch (err) {
      this.logger.error("Cookie Parse Error: ", err)
      cookie = null
    }
    return cookie
  }

  /**
   *
   * @param name
   * @param value
   * @param days {Number}
   * @param crossSubDomain
   * @param isSecure
   * @returns {string}
   */
  set = (name, value, days, crossSubDomain, isSecure) => {
    let expires = "",
      crossDomain = "",
      secure = ""
    if (crossSubDomain) {
      const matches = this.window.location.hostname.match(
          /[a-z0-9][a-z0-9-]+\.[a-z.]{2,6}$/i,
        ),
        domain = matches ? matches[0] : ""
      crossDomain = domain ? "; domain=." + domain : ""
    }
    if (days) {
      const date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = "; expires=" + date.toGMTString()
    }
    if (isSecure) {
      secure = "; secure"
    }
    const cookieValue =
      name +
      "=" +
      encodeURIComponent(value) +
      expires +
      "; path=/" +
      crossDomain +
      secure
    //this.cookie = cookieValue; //FIXME not working why ?
    this.window.document.cookie = cookieValue
    this.logger.debug("Cookie Set: ", cookieValue)
    return cookieValue
  }

  /**
   *
   * @param name
   * @param crossSubDomain
   */
  remove = (name, crossSubDomain) => {
    this.set(name, "", -1, crossSubDomain)
    this.logger.debug("Cookie Removed: ", name, crossSubDomain)
  }
}
