import { createTrackedSelector } from "react-tracked"
import { defineModule } from "zoov"

const initialState = {
  key: "",
}

const DocsModule = defineModule(initialState)
  .actions({
    setKey: (draft, key) => {
      draft.key = key
    },
  })
  .build()

export const { useActions, useComputed } = DocsModule

const useStateValues = createTrackedSelector(DocsModule.useState)

export const useSelector = () => {
  const state = useStateValues()
  return { ...state }
}

export const DOCS_LINKS = {
  OVERVIEW: {
    PRODUCT_GUIDES: {
      name: "Product Guides",
      link: "https://guides.apxor.com/product-guides",
    },
    TEST_DEVICES: {
      name: "Adding a Test Device",
      link: "https://guides.apxor.com/product-guides/adding-a-test-device",
    },
    NEW_APP: {
      name: "Adding a New App",
      link: "https://guides.apxor.com/getting-started-with-apxor/adding-a-new-app",
    },
    CUSTOM_REPORTS: {
      name: "Custom Reports",
      link: "https://guides.apxor.com/product-guides/custom-reports",
    },
  },
  TEST_DEVICE: {
    TEST_DEVICES: {
      name: "Adding a Test Device",
      link: "https://guides.apxor.com/product-guides/adding-a-test-device",
    },
  },
  CUSTOM_REPORTS: {
    CUSTOM_REPORTS: {
      name: "Custom Reports",
      link: "https://guides.apxor.com/product-guides/custom-reports",
    },
  },
  CAMPAIGN_LISTING_PAGE: {
    CAMPAIGN_LISTING_PAGE: {
      name: "Documentation",
      link: "https://guides.apxor.com/product-guides/create-a-campaign/campaign-listings-page",
    },
  },
  CREATE_CAMPAIGN_DESIGN: {
    CAMPAIGN_DESIGNS: {
      name: "Explore Templates",
      link: "https://guides.apxor.com/product-guides/create-a-campaign/campaign-designs",
    },
    CREATE_CAMPAIGN: {
      name: "Dashboard to Guide",
      link: "https://guides.apxor.com/product-guides/create-a-campaign",
    },
    A_B_TESTING: {
      name: "A/B Testing",
      link: "https://guides.apxor.com/product-guides/create-a-campaign/a-b-testing",
    },
  },
  CAMPAIGN_TARGET: {
    CAMPAIGN_TARGET: {
      name: "Target",
      link: "https://guides.apxor.com/product-guides/create-a-campaign/target",
    },
    CAMPAIGN_TRIGGER: {
      name: "Trigger",
      link: "https://guides.apxor.com/product-guides/create-a-campaign/trigger",
    },
  },
  CAMPAIGN_SCHEDULE: {
    CAMPAIGN_SCHEDULE: {
      name: "Schedule and Limit",
      link: "https://guides.apxor.com/product-guides/create-a-campaign/schedule-and-limit",
    },
  },
  CAMPAIGN_REVIEW: {
    CAMPAIGN_REVIEW: {
      name: "Review and Test",
      link: "https://guides.apxor.com/product-guides/create-a-campaign/review-and-test",
    },
    CAMPAIGN_PUBLISH: {
      name: "Set Priority and Publish",
      link: "https://guides.apxor.com/product-guides/create-a-campaign/set-priority-and-publish",
    },
  },
  CAMPAIGN_ANALYTICS: {
    CAMPAIGN_ANALYTICS: {
      name: "Campaign Analytics",
      link: "https://guides.apxor.com/product-guides/create-a-campaign/campaign-analytics",
    },
  },
  SURVEYS_LISTING: {
    SURVEYS_LISTING: {
      name: "Documentation",
      link: "https://guides.apxor.com/product-guides/create-a-survey/survey-listings-page",
    },
  },
  SURVEYS_DESIGNS_CREATE: {
    SURVEYS_DESIGNS: {
      name: "Survey Designs",
      link: "https://guides.apxor.com/product-guides/create-a-survey/survey-designs",
    },
    SURVEY_CREATE: {
      name: "Create a Survey",
      link: "https://guides.apxor.com/product-guides/create-a-survey",
    },
  },
  SURVEY_TARGET: {
    SURVEY_TARGET: {
      name: "Target",
      link: "https://guides.apxor.com/product-guides/create-a-survey/target",
    },
    SURVEY_TRIGGER: {
      name: "Trigger",
      link: "https://guides.apxor.com/product-guides/create-a-survey/trigger",
    },
  },
  SURVEY_SCHEDULE: {
    SURVEY_SCHEDULE: {
      name: "Schedule and Limit",
      link: "https://guides.apxor.com/product-guides/create-a-survey/schedule-and-limit",
    },
  },
  SURVEY_REVIEW: {
    SURVEY_REVIEW: {
      name: "Review and Test",
      link: "https://guides.apxor.com/product-guides/create-a-survey/review-and-test",
    },
    SURVEY_PUBLISH: {
      name: "Publish",
      link: "https://guides.apxor.com/product-guides/create-a-survey/publish",
    },
  },
  SURVEY_ANALYTICS: {
    SURVEY_ANALYTICS: {
      name: "Survey Analytics",
      link: "https://guides.apxor.com/product-guides/create-a-survey/survey-analytics",
    },
  },
}

export const DOCS_LINKS_KEYS = {
  OVERVIEW: "OVERVIEW",
  CAMPAIGN_LISTING_PAGE: "CAMPAIGN_LISTING_PAGE",
  TEST_DEVICE: "TEST_DEVICE",
  CUSTOM_REPORTS: "CUSTOM_REPORTS",
  CREATE_CAMPAIGN_DESIGN: "CREATE_CAMPAIGN_DESIGN",
  CAMPAIGN_TARGET: "CAMPAIGN_TARGET",
  CAMPAIGN_SCHEDULE: "CAMPAIGN_SCHEDULE",
  CAMPAIGN_REVIEW: "CAMPAIGN_REVIEW",
  CAMPAIGN_ANALYTICS: "CAMPAIGN_ANALYTICS",
  SURVEYS_LISTING: "SURVEYS_LISTING",
  SURVEYS_DESIGNS_CREATE: "SURVEYS_DESIGNS_CREATE",
  SURVEY_TARGET: "SURVEY_TARGET",
  SURVEY_SCHEDULE: "SURVEY_SCHEDULE",
  SURVEY_REVIEW: "SURVEY_REVIEW",
  SURVEY_ANALYTICS: "SURVEY_ANALYTICS",
}
