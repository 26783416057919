import { makeStyles } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    "& *": {
      margin: props.applyMargin ? "3px 5px" : "unset",
    },
  }),
}))

export default function RowDiv({
  children,
  styles = {},
  center = false,
  applyMargin = false,
  ...otherProps
}) {
  const classes = useStyles({ applyMargin })

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: center ? "center" : "unset",
        ...styles,
      }}
      className={classes.root}
      {...otherProps}
    >
      {children}
    </div>
  )
}
