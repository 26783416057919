import { useEffect, useRef } from "react"

/**
 * A custom hook that returns a ref and a function to focus the ref.
 * @returns {[React.MutableRefObject<HTMLElement>, Function<void>]} The ref to the element that should be focused.
 */
export default function useFocus(disabled = false) {
  const htmlElRef = useRef(null)

  /**
   * Focuses the ref.
   * @returns {void}
   */
  function setFocus() {
    htmlElRef.current && htmlElRef.current.focus()
  }

  useEffect(() => {
    if (disabled) {
      setFocus()
    }
  }, [disabled])

  return [htmlElRef, setFocus]
}
