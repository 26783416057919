import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined"
import React, { useState } from "react"
import { isPropertyDefined } from "../../../../../../api"
import {
  DEMO_ECOMMERCE_APP_ID,
  isExperienceDashboard,
} from "../../../../../../config"
import { useAuth } from "../../../../../../contexts/AuthContext"
import { copyToClipboard } from "../../../../../../utils"
import { useDeepCompareEffect } from "../../../../../../utils/use-deep-compare"
import { SET_TEST_DEVICES } from "../../../../../Auth/constants"
import { useDispatch, useTrackedState } from "../../../../../Auth/store"
import ColumnDiv from "../../../../../ReusableComponents/ColumnDiv"
import RowDiv from "../../../../../ReusableComponents/RowDiv"
import { getTestDevicesAPI } from "../../../../common/actions"
import appInstallQRCodeImg from "./assets/app-install-qr-code.png"

const expectations = [
  "Create a campaign within 15 mins!",
  "Create a survey and get to know your users!",
  "Understand how your campaigns have affected the retention, uninstalls, session length and many more!",
]

const TableLabelCell = withStyles({
  root: {
    fontWeight: "500",
    fontSize: "13px",
    color: "rgba(0, 40, 69, 1)",
    opacity: "0.6",
    border: "none",
    padding: "8px 0px",
  },
})(TableCell)

const TableValueCell = withStyles({
  root: {
    fontWeight: "600",
    fontSize: "13px",
    color: "#002845",
    border: "none",
    padding: "8px 0px",
  },
})(TableCell)

export const PulseAnimatedCell = withStyles({
  "@keyframes pulse": {
    "50%": {
      opacity: "0.7",
    },
  },
  root: {
    width: "min-content",
    animation: "$pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite",
    padding: "5px 10px",
    fontWeight: "600",
    fontSize: "12px",
    borderRadius: "2px",
  },
})(Typography)

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: theme.breakpoints.values.xl,
    backgroundColor: theme.palette.common.white,
    color: "#002845",
    boxShadow: "0px 4.985915184020996px 49.859153747558594px 0px #00284529",
    fontSize: "14px",
    fontWeight: "600",
    borderRadius: "3px",
    padding: "8px",
  },
}))(Tooltip)

export default function ExperienceDemoFinishStep({
  close_dialog,
  just_display_access_code = false,
}) {
  const auth = useAuth()
  const {
    user: { access_code, test_devices = [] },
  } = useTrackedState()
  const dispatch = useDispatch()

  const [prevTestDevices, setPrevTestDevices] = useState(test_devices ?? [])
  const [openDemoTestDevicesDropDown, setOpenDemoTestDevicesDropDown] =
    useState(false)

  useDeepCompareEffect(() => {
    let timeout
    ;(async function getStatus() {
      const devices = await getTestDevicesAPI(
        auth,
        DEMO_ECOMMERCE_APP_ID,
        {},
        isExperienceDashboard(),
      )
      if (devices.length === prevTestDevices?.length) {
        setPrevTestDevices(devices)
        timeout = setTimeout(() => getStatus(), 5000)
      }
      dispatch({
        type: SET_TEST_DEVICES,
        payload: devices,
      })
    })()

    return () => {
      clearTimeout(timeout)
    }
  }, [auth, dispatch, prevTestDevices])

  return (
    <Grid
      container
      spacing={10}
      style={{
        marginTop: !just_display_access_code && "10px",
        alignItems: just_display_access_code ? "center" : "flex-start",
      }}
    >
      <Grid
        item
        xs={8}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "center",
          }}
        >
          {just_display_access_code ? (
            <Typography
              style={{
                fontSize: "25px",
                fontWeight: "700",
              }}
            >
              App Access Code
            </Typography>
          ) : (
            <Typography
              style={{
                fontWeight: "700",
                fontSize: "18px",
                marginBottom: "14px",
              }}
            >
              Now install the demo application on your device
            </Typography>
          )}
          {just_display_access_code && (
            <Typography
              style={{
                marginTop: "4px",
                marginBottom: "42px",
                fontSize: "13px",
                fontWeight: "500",
                color: "#002845",
                opacity: "0.6",
              }}
            >
              This code can be given to your team members to access the demo app
              and view the campaigns or surveys
            </Typography>
          )}
          <TableContainer
            style={{
              marginBottom: "32px",
            }}
          >
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableLabelCell>Application:</TableLabelCell>
                  <TableValueCell>Apxor Demo</TableValueCell>
                </TableRow>
                <TableRow>
                  <TableLabelCell>Download:</TableLabelCell>
                  <TableValueCell>
                    Scan QR Code (or) Go to Playstore &gt; Install the App
                    "Apxor Demo"
                  </TableValueCell>
                </TableRow>
                <TableRow>
                  <TableLabelCell></TableLabelCell>
                  <TableValueCell>
                    <ColumnDiv styles={{ gap: "9px", maxWidth: "150px" }}>
                      <img
                        src={appInstallQRCodeImg}
                        alt="QR Code"
                        height="150px"
                        width="150px"
                      />
                      <Button
                        variant="text"
                        startIcon={<FileCopyOutlinedIcon />}
                        color="primary"
                        onClick={() => {
                          copyToClipboard(
                            "https://play.google.com/store/apps/details?id=com.apxor.demo",
                          )
                        }}
                      >
                        Copy Link
                      </Button>
                    </ColumnDiv>
                  </TableValueCell>
                </TableRow>
                <TableRow>
                  <TableLabelCell>Access Code:</TableLabelCell>
                  <TableCell
                    style={{
                      border: "none",
                      padding: "8px 0px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "700",
                        fontSize: "26px",
                        color: "rgba(0, 40, 69, 1)",
                      }}
                    >
                      {access_code}
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableLabelCell>
                    {test_devices?.length === 0 ? "Status" : "Test Devices"}:
                  </TableLabelCell>
                  <TableCell
                    style={{
                      border: "none",
                      padding: "8px 0px",
                    }}
                  >
                    {!just_display_access_code &&
                    [0, 1].some((num) => num === test_devices?.length) ? (
                      <RowDiv
                        center
                        styles={{
                          gap: "8px",
                        }}
                      >
                        <PulseAnimatedCell
                          style={{
                            color:
                              test_devices?.length === 0
                                ? "rgba(172, 131, 12, 1)"
                                : "#22543D",
                            background:
                              test_devices?.length === 0
                                ? "rgba(252, 241, 211, 1)"
                                : "#C6F6D5",
                          }}
                        >
                          {test_devices?.length === 0
                            ? "Pending..."
                            : "Success!"}
                        </PulseAnimatedCell>
                        {test_devices?.length !== 0 && (
                          <Typography
                            style={{
                              fontWeight: "600",
                              fontSize: "12px",
                              lineHeight: "16px",
                              letterSpacing: "0.01em",
                              color: "#676767",
                            }}
                          >
                            "
                            {test_devices[0]?.nick_name ??
                              test_devices[0]?.model}
                            " added as a test device
                          </Typography>
                        )}
                      </RowDiv>
                    ) : (
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontWeight: "600",
                            fontSize: "13px",
                          }}
                        >
                          {test_devices?.length}
                        </Typography>
                        <ClickAwayListener
                          onClickAway={() => {
                            setOpenDemoTestDevicesDropDown(false)
                          }}
                        >
                          <StyledTooltip
                            placement="right-end"
                            onClose={() => {
                              setOpenDemoTestDevicesDropDown(false)
                            }}
                            open={openDemoTestDevicesDropDown}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <List>
                                {test_devices?.map((device) => {
                                  const customId = device.custom_id
                                    ? ` (${device.custom_id})`
                                    : ""
                                  const primary = isPropertyDefined(
                                    device,
                                    "nick_name",
                                  )
                                    ? device.nick_name
                                    : device.model
                                  const secondary = isPropertyDefined(
                                    device,
                                    "nick_name",
                                  )
                                    ? `${device.id}${customId} · ${device.model}`
                                    : `${device.id}${customId}`
                                  return (
                                    <ListItem key={device.id}>
                                      <ListItemText
                                        primary={<strong>{primary}</strong>}
                                        secondary={secondary}
                                      />
                                    </ListItem>
                                  )
                                })}
                              </List>
                            }
                          >
                            <IconButton
                              size="small"
                              onClick={() => {
                                setOpenDemoTestDevicesDropDown(true)
                              }}
                            >
                              {openDemoTestDevicesDropDown ? (
                                <ExpandLessIcon fontSize="small" />
                              ) : (
                                <ExpandMoreIcon fontSize="small" />
                              )}
                            </IconButton>
                          </StyledTooltip>
                        </ClickAwayListener>
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {!just_display_access_code && (
            <>
              <Typography
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                }}
              >
                What can you expect?
              </Typography>
              <ul
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  marginTop: "14px",
                  padding: "0px",
                }}
              >
                {expectations.map((exp, index) => (
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginBottom:
                        index !== expectations.length - 1 ? "16px" : "0px",
                    }}
                  >
                    <CheckCircleIcon
                      style={{
                        fontSize: "14px",
                        color: "rgba(20, 141, 0, 1)",
                        marginRight: "12px",
                      }}
                    />
                    <span
                      style={{
                        fontWeight: "500",
                        fontSize: "13px",
                        color: "rgba(0, 40, 69, 1)",
                      }}
                    >
                      {exp}
                    </span>
                  </li>
                ))}
              </ul>
            </>
          )}
          {just_display_access_code && (
            <Button
              size="large"
              variant="contained"
              color="primary"
              style={{
                color: "white",
                fontWeight: "700",
                fontSize: "16px",
              }}
              onClick={close_dialog}
            >
              Okay
            </Button>
          )}
        </Box>
      </Grid>
      <Grid
        item
        xs={4}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <img
          src={`https://storage.googleapis.com/apx_frontend-assets/demo-installation-${
            just_display_access_code ? "half" : "full"
          }.gif`}
          width="75%"
          alt="Go Grocery App"
        />
      </Grid>
    </Grid>
  )
}
