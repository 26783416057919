import { Box, Button, Input, Link, Typography } from "@material-ui/core"
import { useCallback, useState } from "react"
import { Link as RouterLink } from "react-router-dom"
import useApxorClient from "../../../../utils/use-apxor-client"
import { apxTheme } from "../../../ApplicationFrame/styling/CustomCSS"
import { PulseAnimatedCell } from "../../../Dashboard/components/dashboard/components/sales-sandbox/ExperienceDemoFinishStep"
import Loading from "../../../ReusableComponents/Loading"
import Snackbar from "../../../ReusableComponents/Snackbar"
import mailSentImg from "../../assets/mail-sent.svg"
import { SET_AUTH_ERROR, SET_EMAIL_ID } from "../../constants"
import { useDispatch, useTrackedState } from "../../store"
import useIntegration from "../hooks/use-integration"
import AuthLayout from "../layouts/AuthLayout"

export default function ForgotPasswordPage() {
  const { logEvent } = useApxorClient()
  const {
    user: { email },
    auth_error,
    auth_loading,
  } = useTrackedState()
  const dispatch = useDispatch()
  const { isSubmitDisabled, sendPasswordResetEmail } = useIntegration()

  const [showEmailSentContent, setShowEmailSentContent] = useState(false)
  const [mailResent, setMailResent] = useState(false)

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault()

      if (await sendPasswordResetEmail(email)) {
        setShowEmailSentContent(true)
        logEvent("ResetPasswordClicked")
      }
    },
    [email, logEvent, sendPasswordResetEmail],
  )

  return showEmailSentContent ? (
    <AuthLayout
      title="Reset Password Link Sent"
      icon={
        <img
          src={mailSentImg}
          alt="Mail Icon"
          style={{
            display: "block",
          }}
        />
      }
    >
      {!auth_error && !auth_loading && showEmailSentContent && mailResent && (
        <Snackbar
          onClose={() => {
            setMailResent(false)
          }}
        >
          <strong>Please check your mail to reset password.</strong>
        </Snackbar>
      )}
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "center",
          maxWidth: "60%",
        }}
      >
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: "600",
            marginBottom: "45px",
          }}
        >
          We have sent you an email with instructions on how to reset your
          password along with the reset password link to{" "}
          <span
            style={{
              fontWeight: "700",
              color: apxTheme.palette.primary.main,
            }}
          >
            {email}
          </span>
          .
        </Typography>
        <Typography
          style={{
            fontSize: "13px",
            fontWeight: "600",
            color: "#4D697D",
          }}
        >
          Didn&apos;t recieve the email?{" "}
          {auth_loading ? (
            <PulseAnimatedCell
              style={{
                cursor: "pointer",
                display: "inline",
                color: "rgba(172, 131, 12, 1)",
                background: "rgba(252, 241, 211, 1)",
                padding: "3px",
              }}
            >
              Resending...
            </PulseAnimatedCell>
          ) : (
            <span
              style={{
                color: apxTheme.palette.primary.main,
                cursor: "pointer",
                padding: "3px",
              }}
              onClick={async (e) => {
                await handleSubmit(e)
                setMailResent(true)
              }}
            >
              Resend
            </span>
          )}
        </Typography>
      </Box>
    </AuthLayout>
  ) : (
    <AuthLayout
      title="Forgot Password"
      withBackButton
    >
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "start",
          marginRight: "24px",
          flex: "1",
        }}
      >
        <label
          htmlFor="email"
          style={{
            marginTop: "16px",
            fontSize: "13px",
            fontWeight: "500",
            color: "#667A8A",
          }}
        >
          Email ID
        </label>
        <Input
          name="email"
          size="medium"
          id="email"
          type="email"
          value={email}
          onChange={(e) => {
            dispatch({
              type: SET_EMAIL_ID,
              payload: e.target.value,
            })
            if (auth_error?.length > 0) {
              dispatch({
                type: SET_AUTH_ERROR,
                payload: "",
              })
            }
          }}
          style={{
            width: "60%",
            marginTop: "4px",
            marginBottom: "45px",
            border: `1px solid ${apxTheme.palette.action.selected}`,
            fontWeight: "600",
            fontSize: "14px",
            padding: "4.5px",
          }}
        />
        <Box
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            marginBottom: "12px",
            width: auth_error ? "100%" : "60%",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            disabled={isSubmitDisabled || auth_error !== ""}
            style={{
              width: auth_error ? "30%" : "60%",
              height: 48,
            }}
          >
            {auth_loading ? (
              <Loading
                size={28}
                color="#FFFFFF"
              />
            ) : (
              "Send Reset Password Link"
            )}
          </Button>
          <span
            style={{
              marginLeft: "12px",
              fontSize: "16px",
              fontWeight: "500",
              color: "#F12D2D",
            }}
          >
            {auth_error !== "" && auth_error}
          </span>
        </Box>
        <Typography
          style={{
            fontSize: "13px",
            fontWeight: "600",
            color: "#4D697D",
          }}
        >
          Don&apos;t have an account?{" "}
          <Link
            to="/sign_up"
            component={RouterLink}
            style={{
              color: apxTheme.palette.primary.main,
            }}
          >
            Sign Up
          </Link>
        </Typography>
      </form>
    </AuthLayout>
  )
}
