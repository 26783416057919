import { makePatchRequest } from "../../../../common/actions"

const TIMEZONE = "time-zone"
const FEATURES = "apps/features/update"

const debug = process.env.NODE_ENV !== "production"

export function updateAppTZSettingsAPI(auth, appId, body) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = () => {
    return false
  }

  return makePatchRequest(
    TIMEZONE,
    auth,
    appId,
    { notify: debug ? "false" : "true" },
    body,
    onSuccessCallback,
    onFailureCallback,
  )
}

export function updateFeaturesListAPI(auth, appId, queryparams, body = {}) {
  const onSuccessCallback = (response) => {
    return response
  }

  const onFailureCallback = () => {
    return false
  }

  return makePatchRequest(
    FEATURES,
    auth,
    appId,
    queryparams,
    body,
    onSuccessCallback,
    onFailureCallback,
  )
}
