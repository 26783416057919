import useDebounced from "../../../../../utils/use-debounced"
import ActionsTextField from "../../../../ReusableComponents/ActionsTextField"
import { SET_AUTH_ERROR, SET_EMAIL_ID } from "../../../constants"
import { useDispatch, useTrackedState } from "../../../store"
import { demoValidateEmailAPI } from "../../actions"

export default function ExperienceDemoEmailForm() {
  const {
    user: { email },
    auth_error,
  } = useTrackedState()
  const dispatch = useDispatch()

  const handleEmailChange = useDebounced(async (event) => {
    dispatch({
      type: SET_EMAIL_ID,
      payload: event.target.value,
    })
    const isValidEmail = await demoValidateEmailAPI(event.target.value)
    if (!isValidEmail) {
      dispatch({
        type: SET_AUTH_ERROR,
        payload: "Please enter a work email",
      })
    }
  }, 700)

  return (
    <>
      <label
        htmlFor="email"
        style={{
          color: "rgba(102, 122, 138, 1)",
          fontSize: "14px",
          fontWeight: "500",
        }}
      >
        Work Email
      </label>
      <ActionsTextField
        multiline={false}
        id="email"
        type="email"
        name="email"
        value={email}
        onChange={(e) => {
          if (auth_error.length > 0) {
            dispatch({
              type: SET_AUTH_ERROR,
              payload: "",
            })
          }
          handleEmailChange(e)
        }}
        focused
        margin="normal"
        fullWidth
        validationErrors={auth_error}
        InputProps={{
          autoComplete: "email",
          style: {
            fontSize: "15px",
            fontWeight: "600",
            padding: "8px 6px",
            borderColor: auth_error ? "rgba(229, 59, 59, 1)" : "#CCD4DA",
          },
        }}
        style={{
          marginTop: "6px",
          marginBottom: "0px",
        }}
      />
    </>
  )
}
