import { IconButton } from "@material-ui/core"
import { Visibility, VisibilityOff } from "@material-ui/icons"
import { useState } from "react"
import { StyledInput } from "../../../Profile/pages/ProfilePage"
import ThemedTooltip from "../../../ReusableComponents/ThemedTooltip"

export default function PasswordInput({ onChange, value, ...props }) {
  const [typePassword, setTypePassword] = useState(true)

  return (
    <StyledInput
      size="medium"
      type={typePassword ? "password" : "text"}
      value={value}
      onChange={onChange}
      endAdornment={
        <ThemedTooltip
          title={typePassword ? "Show Password" : "Hide Password"}
          placement="right"
          dark
        >
          <IconButton
            aria-label="Toggle password visibility"
            size="small"
            style={{
              color: "#002845",
              opacity: 0.3,
            }}
            onClick={() => setTypePassword(!typePassword)}
          >
            {typePassword ? (
              <VisibilityOff
                style={{
                  fontSize: "1.3rem",
                }}
              />
            ) : (
              <Visibility
                style={{
                  fontSize: "1.3rem",
                }}
              />
            )}
          </IconButton>
        </ThemedTooltip>
      }
      style={props.style}
      {...props}
    />
  )
}
