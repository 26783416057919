import produce from "immer"
import {
  SET_BUNDLE_IDS,
  SET_SETTINGS_ERROR,
  SET_SETTINGS_LOADING,
  SET_TEST_DEVICES,
} from "./constants"

export const initial_state = {
  settings_loading: false,
  settings_error: "",
  bundle_ids: [],
  test_devices: [],
}

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case SET_SETTINGS_LOADING:
      draft.settings_loading = action.payload
      break
    case SET_SETTINGS_ERROR:
      draft.settings_error = action.payload
      break
    case SET_BUNDLE_IDS:
      draft.bundle_ids = action.payload
      break
    case SET_TEST_DEVICES:
      draft.test_devices = action.payload
      break
    default:
      break
  }
})
