import { Box } from "@material-ui/core"
import React, { useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { DEMO_ECOMMERCE_APP_ID } from "../../../../config"
import { useDeepCompareEffect } from "../../../../utils/use-deep-compare"
import { useTrackedState } from "../../store"
import ExperienceDemoAuthDialog from "../components/ExperienceDemoAuthDialog"

export default function DemoSignInPage() {
  const { orgId } = useParams()
  const { push } = useHistory()
  const { user } = useTrackedState()

  const [showDemoAuthDialog, setShowDemoAuthDialog] = useState(true)

  useDeepCompareEffect(() => {
    if (
      user &&
      user?.email &&
      user?.email !== "" &&
      user?.access_code &&
      user?.access_code !== "" &&
      user?.test_devices?.length >= 1 &&
      !showDemoAuthDialog
    ) {
      push(`/orgs/${orgId}/apps/${DEMO_ECOMMERCE_APP_ID}/dashboard`)
    }
  }, [orgId, push, showDemoAuthDialog, user])

  const onClose = () => {
    setShowDemoAuthDialog(false)
  }

  return (
    <Box
      style={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url("/assets/img/demo-bg-img.png")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        marginInline: "-8px",
        marginBlock: "-10px",
      }}
    >
      <ExperienceDemoAuthDialog
        open={showDemoAuthDialog}
        onClose={onClose}
      />
    </Box>
  )
}
