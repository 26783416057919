import { Box, Button } from "@material-ui/core"
import { useCallback } from "react"
import { useHistory } from "react-router-dom"
import { apxTheme } from "../../../../ApplicationFrame/styling/CustomCSS"
import Loading from "../../../../ReusableComponents/Loading"
import { SET_CONFIRM_PASSWORD, SET_PASSWORD } from "../../../constants"
import { useDispatch, useTrackedState } from "../../../store"
import useIntegration from "../../hooks/use-integration"
import PasswordInput from "../PasswordInput"
import PasswordStrengthChecks from "../PasswordStrengthChecks"

export default function SetPasswordForm() {
  const { isSubmitDisabled, setPassword } = useIntegration()
  const {
    user: { password, confirm_password },
    auth_error,
    auth_loading,
  } = useTrackedState()
  const dispatch = useDispatch()
  const {
    push,
    location: { search },
  } = useHistory()

  const queryParams = new URLSearchParams(search)

  const name = queryParams.get("name") ?? ""
  const token = queryParams.get("token") ?? ""

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault()

      if (await setPassword(name, password, token)) {
        push("/login")
      }
    },
    [name, password, push, setPassword, token],
  )

  return (
    <>
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "start",
          marginRight: "24px",
          flex: "1",
        }}
      >
        <label
          htmlFor="password"
          style={{
            marginTop: "16px",
            fontSize: "13px",
            fontWeight: "500",
            color: "#667A8A",
          }}
        >
          Password
        </label>
        <PasswordInput
          name="password"
          id="password"
          value={password}
          onChange={(e) => {
            dispatch({
              type: SET_PASSWORD,
              payload: e.target.value,
            })
          }}
          style={{
            width: "100%",
            marginTop: "4px",
            border: `1px solid ${apxTheme.palette.action.selected}`,
            fontWeight: "600",
            fontSize: "14px",
            padding: "4.5px",
          }}
        />
        <label
          htmlFor="confirm_password"
          style={{
            marginTop: "16px",
            fontSize: "13px",
            fontWeight: "500",
            color: "#667A8A",
          }}
        >
          Confirm Password
        </label>
        <PasswordInput
          name="confirm_password"
          id="confirm_password"
          value={confirm_password}
          onChange={(e) => {
            dispatch({
              type: SET_CONFIRM_PASSWORD,
              payload: e.target.value,
            })
          }}
          style={{
            width: "100%",
            marginTop: "4px",
            border: `1px solid ${apxTheme.palette.action.selected}`,
            marginBottom: "45px",
            fontWeight: "600",
            fontSize: "14px",
            padding: "4.5px",
          }}
        />
        <Box
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            width: auth_error ? "100%" : "60%",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            disabled={isSubmitDisabled}
            style={{
              width: auth_error ? "30%" : "50%",
              height: 48,
            }}
          >
            {auth_loading ? (
              <Loading
                size={28}
                color="#FFFFFF"
              />
            ) : (
              "Next"
            )}
          </Button>
          <span
            style={{
              marginLeft: "12px",
              fontSize: "16px",
              fontWeight: "500",
              color: "#F12D2D",
            }}
          >
            {auth_error !== "" && auth_error}
          </span>
        </Box>
      </form>
      <PasswordStrengthChecks
        styles={{
          paddingBottom: "5.2rem",
        }}
      />
    </>
  )
}
