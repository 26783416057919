/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { checkDeps, IS_DEV, useDeepCompareMemoize } from "./utils"

/**
 * The `useDeepCompareEffect` hook is a drop-in replacement for `useEffect` that will only trigger if the **array** or **object** dependencies of that effect have changed.
 * @param {import("react").EffectCallback} effect The effect function to run.
 * @param {import("react").DependencyList} deps The dependencies of the effect function.
 */
export default function useDeepCompareEffect(effect, deps) {
  if (IS_DEV) {
    checkDeps(deps, "useDeepCompareEffect")
  }

  useEffect(effect, useDeepCompareMemoize(deps))
}

/**
 * The `useDeepCompareEffectNoCheck` hook is a drop-in replacement for `useEffect` that will only trigger if the **array** or **object** dependencies of that effect have changed, but does not check the dependencies for validity, i.e., null or polymorphic values.
 * @param {import("react").EffectCallback} effect The effect function to run.
 * @param {import("react").DependencyList} deps The dependencies of the effect function.
 */
export function useDeepCompareEffectNoCheck(effect, deps) {
  useEffect(effect, useDeepCompareMemoize(deps))
}
