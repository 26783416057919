import { Button, Typography } from "@material-ui/core"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useMemo } from "react"
import { useParams } from "react-router-dom"
import { toast } from "sonner"
import useApxorClient from "../../../utils/use-apxor-client"
import useIntegration from "../../Auth/integration-experience/hooks/use-integration"
import HStack from "../../ReusableComponents/HStack"
import Loading from "../../ReusableComponents/Loading"
import ModalPopup from "../../ReusableComponents/ModalPopup"
import VStack from "../../ReusableComponents/VStack"

export default function SDKMonitoringDialog({
  open,
  onClose,
  selectedAppId,
  enabled,
  setMonitoring,
}) {
  const { logEvent } = useApxorClient()
  const { updateMonitoringStatus } = useIntegration()

  const { appId } = useParams()

  const app_id = useMemo(
    () => (selectedAppId ? selectedAppId : appId),
    [appId, selectedAppId],
  )

  const queryClient = useQueryClient()

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: async () => {
      await updateMonitoringStatus(app_id, !enabled)
    },
    mutationKey: ["update-monitoring-status", app_id, enabled],
    onSuccess: async () => {
      await queryClient.invalidateQueries(["apps"])
      toast.success(
        `SDK Monitoring ${enabled ? "disabled" : "enabled"} successfully!`,
      )
      setMonitoring(!enabled)
      logEvent("LP_SDK_Monitoring_Toggled", {
        cta_type: enabled ? "enable" : "disable",
      })
    },
    onError: () => {
      toast.error("Something went wrong. Please try again later.")
    },
    onSettled: () => {
      onClose()
    },
  })

  return (
    <ModalPopup
      open={open}
      onClose={onClose}
      maxWidth="400px"
      title="SDK Monitoring?"
    >
      <VStack spacing="16px">
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: "500",
            color: "#002845",
          }}
        >
          Are you sure want to <b>{enabled ? "DISABLE" : "ENABLE"}</b> SDK
          monitoring?
        </Typography>
        <HStack
          align="center"
          justify="end"
          spacing="30px"
          style={{
            marginTop: "16px",
          }}
        >
          <Button
            variant="text"
            color="default"
            size="large"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={isLoading}
            onClick={async () => {
              await mutateAsync()
            }}
          >
            {isLoading ? (
              <Loading
                size={28}
                color="#FFFFFF"
              />
            ) : (
              <>{enabled ? "Disable" : "Enable"}</>
            )}
          </Button>
        </HStack>
      </VStack>
    </ModalPopup>
  )
}
