// Common Constants
export const SET_USER = "SET_USER"
export const SET_NAME = "SET_NAME"
export const SET_EMAIL_ID = "SET_EMAIL_ID"
export const SET_JOB_TITLE = "SET_JOB_TITLE"
export const SET_APP_ID = "SET_APP_ID"
export const SET_AUTH_LOADING = "SET_AUTH_LOADING"
export const SET_AUTH_ERROR = "SET_AUTH_ERROR"

// Integration Experience Constants
export const SET_PHONE_NUMBER = "SET_PHONE_NUMBER"
export const SET_COMPANY_NAME = "SET_COMPANY_NAME"
export const SET_PASSWORD = "SET_PASSWORD"
export const SET_CONFIRM_PASSWORD = "SET_CONFIRM_PASSWORD"

// Sales SandBox Constants
export const SET_OTP = "SET_OTP"
export const SET_ACCESS_CODE = "SET_ACCESS_CODE"
export const SET_TEST_DEVICES = "SET_TEST_DEVICES"
