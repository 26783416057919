/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react"
import { checkDeps, IS_DEV, useDeepCompareMemoize } from "./utils"

/**
 * The `useDeepCompareCallback` hook is a drop-in replacement for `useCallback` that will only trigger if the **array** or **object** dependencies of that callback have changed.
 * @param {(...args: any[])} callback The callback function to run.
 * @param {import("react").DependencyList} deps The dependencies of the callback function.
 */
export default function useDeepCompareCallback(callback, deps) {
  if (IS_DEV) {
    checkDeps(deps, "useDeepCompareCallback")
  }

  return useCallback(callback, useDeepCompareMemoize(deps))
}

/**
 * The `useDeepCompareCallbackNoCheck` hook is a drop-in replacement for `useCallback` that will only trigger if the **array** or **object** dependencies of that callback have changed, but does not check the dependencies for validity, i.e., null or polymorphic values.
 * @param {(...args: any[])} callback The callback function to run.
 * @param {import("react").DependencyList} deps The dependencies of the callback function.
 */
export function useDeepCompareCallbackNoCheck(callback, deps) {
  return useCallback(callback, useDeepCompareMemoize(deps))
}
