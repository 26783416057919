/**
 * @enum {string}
 */
export const STEPPER_ACTION_TYPES = Object.freeze({
  BACK_TO_APXOR_WEBSITE: "Back to apxor.com",
  CONTINUE: "Continue",
  VERIFY: "Verify",
  PROCEED: "Proceed",
})

/**
 * @enum {string}
 */
export const AUTH_FLOW_STATES = Object.freeze({
  USER: "USER",
  OTP: "OTP",
  FINAL: "FINAL",
})
