import { Fade, Grow, Slide, Typography, Zoom } from "@material-ui/core"
import PropTypes from "prop-types"
import { forwardRef } from "react"
import CustomMaterialUIDialog from "./CustomMaterialUIDialog"
import VStack from "./VStack"

const TRANSITION_TYPES = Object.freeze({
  GROW: "grow",
  ZOOM: "zoom",
  SLIDE: "slide",
  FADE: "fade",
})

const Transition = forwardRef(function Transition(props, ref) {
  const { transitionVariant = TRANSITION_TYPES.GROW } = props
  let Comp = Grow

  switch (transitionVariant) {
    case TRANSITION_TYPES.GROW:
      Comp = Grow
      break
    case TRANSITION_TYPES.ZOOM:
      Comp = Zoom
      break
    case TRANSITION_TYPES.SLIDE:
      Comp = Slide
      break
    case TRANSITION_TYPES.FADE:
      Comp = Fade
      break
    default:
      break
  }

  return (
    <Comp
      ref={ref}
      {...props}
    />
  )
})

export default function ModalPopup({
  open,
  onClose,
  title,
  children,
  maxWidth = "300px",
  cantClose = false,
  transitionVariant = TRANSITION_TYPES.GROW,
  disableCloseByOtherMethods = false,
  ...props
}) {
  return (
    <CustomMaterialUIDialog
      openFlag={open}
      onClose={cantClose ? () => {} : onClose}
      onDialogClose={cantClose ? () => {} : onClose}
      hideCloseIcon={cantClose || disableCloseByOtherMethods}
      noTitleBg
      dialogTitleStyles={{ padding: "0px" }}
      paperStyles={{ maxWidth, borderRadius: "12px" }}
      backdropProps={{
        style: {
          backdropFilter: "blur(1px)",
        },
      }}
      dialogProps={{
        disableBackdropClick: disableCloseByOtherMethods,
        disableEscapeKeyDown: disableCloseByOtherMethods,
        keepMounted: true,
        TransitionComponent: Transition,
        TransitionProps: {
          transitionVariant,
        },
      }}
      dialogContent={
        <VStack
          spacing="30px"
          style={{
            padding: "24px",
          }}
        >
          {/* Title */}
          <Typography
            color="textPrimary"
            style={{
              fontWeight: 700,
              fontSize: 25,
            }}
          >
            {title}
          </Typography>

          {/* Content */}
          {children}
        </VStack>
      }
      {...props}
    />
  )
}

ModalPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
  maxWidth: PropTypes.string,
  transitionVariant: PropTypes.oneOf(Object.values(TRANSITION_TYPES)),
}
