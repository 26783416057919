import { callApi } from "../../api"
import { APP_PLATFORMS } from "../../constants"
import { makeDefaultQueryString } from "../../utils"
import { makeDeleteRequest } from "../common/actions"

const APPS_API = "apps"
const SET_MONITORING_STATUS_API_URL = "apps/monitor"

const PLAY_STORE_ICON_URL = "/assets/img/playStore.ico"
const APP_STORE_ICON_URL = "/assets/img/defaultAppImage.png"
const WEB_ICON_URL = "/assets/img/web.png"
const FAVICON_URL = "/assets/img/favicon.png"
const OMNI_ICON_URL = "/assets/img/omni.png"

const { android, ios, web, omni } = APP_PLATFORMS

const ICONS = {
  [android]: PLAY_STORE_ICON_URL,
  [ios]: APP_STORE_ICON_URL,
  [web]: WEB_ICON_URL,
  [omni]: OMNI_ICON_URL,
}

function getIcon(platform) {
  return ICONS[platform] || FAVICON_URL
}

const makeAddAppModel = (auth, app) => {
  return {
    app_name: app.app_name,
    icon_url: getIcon(app.platform),
    customer_id: auth.user.email,
    bundle_id: app.bundle_id ? app.bundle_id : "",
    platform: app.platform ? app.platform : "",
  }
}

/**
 * @typedef {Object} AppDetailsDto
 * @property {string} app_name The name of the app.
 * @property {string} bundle_id The bundle ID of the app.
 * @property {string} platform The platform of the app.
 */

/**
 * Creates a new app under the given organization.
 * @param {Object} auth The auth object.
 * @param {AppDetailsDto} app The app object.
 * @param {string} orgId The ID of the organization to add the app to.
 * @returns {Promise<string|null>} A promise that resolves to `app_id` of the newly created app, or an `null` if the request failed.
 */
export function addAppAPI(auth, app, orgId) {
  const url = makeDefaultQueryString(APPS_API, auth)
  const config = {
    method: "POST",
    auth: auth,
    body: JSON.stringify(makeAddAppModel(auth, app)),
  }
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return null
  }
  return callApi(
    url + `&orgId=${orgId}`,
    config,
    onSuccessCallback,
    onFailureCallback,
  )
}

/**
 * Deletes an app.
 * @param {Object} auth The auth object.
 * @param {string} appId The ID of the app to delete.
 * @returns {Promise<boolean>} A promise that resolves to `true` if the app was deleted successfully, or `false` otherwise.
 */
export function deleteAppAPI(auth, appId) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response
  }
  return makeDeleteRequest(
    APPS_API,
    auth,
    appId,
    {},
    onSuccessCallback,
    onFailureCallback,
  )
}

export function setMonitoringStatusAPI(auth, appId, monitoringStatus) {
  const url = makeDefaultQueryString(SET_MONITORING_STATUS_API_URL, auth, appId)
  const config = {
    method: "PATCH",
    auth: auth,
    body: JSON.stringify({ monitoring: monitoringStatus }),
  }
  const onSuccessCallback = (json) => {
    return true
  }
  const onFailureCallback = (response) => {
    return false
  }
  return callApi(url, config, onSuccessCallback, onFailureCallback)
}
