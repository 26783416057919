import { Box } from "@material-ui/core"
import PropTypes from "prop-types"
import { alignOptions, justifyOptions } from "./VStack"

/**
 * A horizontal stack of elements.
 * @param {import("react").ReactElement} component The component to use for the stack.
 * @param {string} [justify] The CSS `justify-content` property.
 * @param {string} [align] The CSS `align-items` property.
 * @param {string} [spacing] The CSS `gap` property.
 * @param {import("react").ReactElement} children The children to render inside the flex.
 * @param {import("@material-ui/core").BoxProps} props The props to pass to the flex container.
 * @returns {import("react").ReactElement} The horizontal flex component.
 */
export default function HStack({
  component = "div",
  justify = "start",
  align = "center",
  spacing = "0px",
  children,
  style = {},
  ...props
}) {
  return (
    <Box
      component={component}
      display="flex"
      flexDirection="row"
      justifyContent={justify}
      alignItems={align}
      style={{
        gap: spacing,
        flexDirection: "row",
        justifyContent: justify,
        alignItems: align,
        ...style,
      }}
      {...props}
    >
      {children}
    </Box>
  )
}

HStack.propTypes = {
  component: PropTypes.elementType,
  justify: PropTypes.oneOf(justifyOptions),
  align: PropTypes.oneOf(alignOptions),
  spacing: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
}
