import { callApi } from "../../api"
import { makeDefaultQueryString } from "../../utils"

const UPDATE_PASSWORD_ENDPOINT = "update/password"
const UPDATE_PROFILE_INFO_ENDPOINT = "update/customerInfo"

/**
 * @typedef {Object} UpdatePasswordPayload
 * @property {string} userId The email address of the user.
 * @property {string} oldPassword The current password of the user.
 * @property {string} newPassword The new password of the user.
 */

/**
 * This API updates the password of the user.
 * @param {Object} auth The auth object
 * @param {UpdatePasswordPayload} body The body of the request
 * @returns {Promise<boolean>} A promise that resolves to `true` if the password was updated successfully, `false` otherwise.
 */
export function updatePasswordAPI(auth, body) {
  const config = {
    method: "POST",
    auth,
    body: JSON.stringify(body),
  }
  const onSuccessCallback = () => {
    return true
  }
  const onFailureCallback = () => {
    return false
  }
  return callApi(
    UPDATE_PASSWORD_ENDPOINT,
    config,
    onSuccessCallback,
    onFailureCallback,
  )
}

/**
 * @typedef {Object} ProfileDetailsDTO
 * @property {string} name The new name of the user.
 *
 * @typedef {Object} UpdateProfileReturnDTO
 * @property {string} [name] The latest name of the user.
 * @property {boolean} ok Whether the update was successful or not.
 */

/**
 * This API updates the profile information of the user.
 * @param {Object} auth The auth object.
 * @param {ProfileDetailsDTO} body The body of the request.
 * @returns {Promise<UpdateProfileReturnDTO>} A promise that resolves to the updated profile details.
 */
export function updateProfileDetailsAPI(auth, body) {
  const config = {
    method: "POST",
    auth,
    body: JSON.stringify(body),
  }
  const onSuccessCallback = (json) => {
    return {
      ok: true,
      name: json.name,
    }
  }
  const onFailureCallback = () => {
    return { ok: false }
  }
  return callApi(
    UPDATE_PROFILE_INFO_ENDPOINT,
    config,
    onSuccessCallback,
    onFailureCallback,
  )
}

export function fetchPendingAppApprovalsAPI(auth, userId) {
  const url = makeDefaultQueryString("pending-approvals", auth, null)
  const config = {
    method: "GET",
    auth: auth,
  }
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response
  }
  return callApi(url, config, onSuccessCallback, onFailureCallback)
}
